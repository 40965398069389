import {Component, Input, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {User} from "@kwot/data-models";

@Component({
  selector: 'kwot-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  currentRoute: string;
  @Input() currentUser: User;

  constructor(
    private router: Router
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        const url = event.url.split('/').filter(t => t);
        if (url[1]) {
          this.currentRoute = url[1];
        } else {
          this.currentRoute = 'dashboard';
        }
      }
    })
  }

  ngOnInit(): void {
  }

}
