<div class="products-list">
  <div class="products-list-title">
    <div class="row align-items-center">
      <div class="col-lg-12">
        <div class="back d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <img src="/assets/images/icons/back.svg" class="cursor-pointer" [routerLink]="['/admin', 'dashboard']"/>
            <p class="title-back mb-0 fs-16 cursor-pointer"
               [routerLink]="['/admin', 'dashboard']">{{'back' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="products-list-wrapper">
    <h2 class="fs-32 fw-bold">{{'reviews' | translate}}</h2>
    <div class="custom-table">
      <div class="tag-filters">
        <span class="tag" [ngClass]="{'active': selectedFilter === filter.value}"
              (click)="filterProduct(filter.value)"
              *ngFor="let filter of filters">{{filter.label}}</span>
      </div>
      <kwot-custom-table
        #customTableComponent
        [customOptions]="customDatatableSettings"
        [ajaxFunction]="getAllReviews"
        [tableHead]="tableHead" [tableBody]="tableBody">
      </kwot-custom-table>
      <ng-template #tableHead>
        <thead>
        <tr>
          <td>
            <p
              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
              {{selectedFilter | translate}}
            </p>
          </td>
          <td>
            <p
              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
              {{'buyer' | translate}}
            </p>
          </td>
          <td>
            <p
              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
              {{'review' | translate}}
            </p>
          </td>
          <td></td>
        </tr>
        </thead>
      </ng-template>
      <ng-template #tableBody>
        <tbody *ngIf="reviewsList.length <= 0">
        <tr>
          <td colspan="3">{{'no_data_found' | translate}}</td>
        </tr>
        </tbody>
        <tbody *ngIf="reviewsList.length > 0">
        <tr *ngFor="let review of reviewsList">
          <td *ngIf="selectedFilter == 'product'">
              <p class="mb-0 fs-16 cursor-pointer store-content-details" (click)="reviewInfo(review)">
              <img [lazyLoad]="review?.productId?.coverImage"
                   [defaultImage]="'/assets/images/loaders/skeleton_loader.gif'" class="search-icon me-2" alt="">
              {{review?.productId?.productName}}
            </p>
          </td>

          <td *ngIf="selectedFilter == 'seller'">
            <p class="mb-0 fs-16 cursor-pointer store-content-details" (click)="reviewInfo(review)">
              <img [lazyLoad]="review?.vendorId?.logo"
                   [defaultImage]="'/assets/images/loaders/skeleton_loader.gif'" class="search-icon me-2" alt="">
              {{review?.vendorId?.name}}
            </p>
          </td>
          <td>
            <p class="mb-0 fs-16 cursor-pointer store-content-details">
              <img [lazyLoad]="review?.userId?.image"
                   [ngStyle]="{ height: '25px', width: '25px', borderRadius: '16px'}"
                   [defaultImage]="'/assets/images/loaders/skeleton_loader.gif'" class="search-icon me-2" alt="">
              {{review?.userId?.firstName}} {{review?.userId?.lastName}}
            </p>
          </td>
          <td>
            <div
              class="mb-0 fs-16 store-content-details d-flex flex-column align-items-start justify-content-start">
              <ul class="list-unstyled p-0 m-0 d-flex">
                <li *ngFor="let stars of [].constructor(review.rating || 0)">
                  <img [ngStyle]="{width: '15px', height: '15px'}" src="assets/images/icons/star-icon.svg" alt=""/>
                </li>
                <li *ngFor="let stars of [].constructor(5 - (review.rating || 0))">
                  <img [ngStyle]="{width: '15px', height: '15px'}" src="assets/images/icons/star-border-icon.svg"
                       alt=""/>
                </li>
              </ul>
              {{review.review}}
            </div>
          </td>
          <td class="action">
            <button type="button" class="btn-icon float-end" placement="bottom right"
                    containerClass="custom-popover no-arrow no-last-border"
                    [outsideClick]="true" container="body" [adaptivePosition]="false"
                    [popover]="popTemplate">
              <i class="fas fa-ellipsis-v"></i>
            </button>
            <ng-template #popTemplate>
              <div class="item">
                <a (click)="deleteReviewModel(deleteReviewData, review)">
                  <img src="/assets/images/icons/delete.svg" class="icon"/>
                  <span>{{'delete_review' | translate}}</span>
                </a>
              </div>
            </ng-template>
          </td>
        </tr>
        </tbody>
      </ng-template>

      <ng-template #deleteReviewData>
        <div class="review-model">
          <div class="modal-header justify-content-center">
            <h4 class="modal-title fs-16 fw-bold text-center mb-0">{{'delete_review' | translate}}</h4>
            <button type="button" class="btn-close m-0" (click)="modalRef?.hide()"></button>
          </div>
          <div class="modal-body pt-0">
            <div class="account-content">
              <div class="img-wrapper text-center">
                <img class="img-fluid" src="/assets/images/confirm-background.png"/>
              </div>
              <h2 class="fs-24 fw-bold text-center pt-4 m-0">{{(selectedFilter == 'product' ? 'delete_product_review_note' : 'delete_seller_review_note') | translate}}</h2>
              <p class="fs-16 text-center m-0">{{'delete_account_sub_note' | translate}}</p>
            </div>
          </div>
          <div class="modal-footer d-flex flex-wrap justify-content-evenly m-0 mb-4">
            <button type="button" class="remove-btn fs-14 fw-bold btn-green-border mx-2 btn" (click)="deleteReview()"
                    aria-label="Close">{{'remove_review' | translate}}</button>
            <button type="button" class="cancle-btn fs-14 fw-bold mx-2 btn-action btn" (click)="modalRef?.hide()"
                    aria-label="Close">{{'cancel' | translate}}</button>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
