<div class="auth-component">
  <span id="close"><img class="" [routerLink]="['/', appConfig.type, 'login']" src="assets/images/icons/Cross.svg"
                        alt=""></span>
  <img class="bg-right--img d-none d-md-block" src="assets/images/bg/auth-Bg.png" alt="">
  <img class="bg-right--img d-md-none" src="assets/images/bg/auth-mobile-Bg.png" alt="">
  <div class="container">
    <div class="row">
      <div class="col-xl-4 col-md-5 pt-56 pt-md-36">
        <div class="action--form">

          <form class="reset-password-form" (ngSubmit)="!resetPasswordForm.invalid && submitForm()" novalidate
                [formGroup]="resetPasswordForm">
            <h1 class="register-title text-center">{{'create_password_new' | translate}}</h1>
            <div class="form-group mt-4">
              <div class="position-relative">
                <div class="d-flex justify-content-between">
                  <label class="mb-0 ms-2 fs-12 fw-bold">{{'new_password' | translate}} *</label>
                  <ng-container *ngIf="(form.newPassword.dirty || form.newPassword.touched) && form.newPassword.errors">
                    <label *ngIf="form.newPassword.errors.required"
                           class="text-danger mb-0 me-2 text-end fs-10 fw-bold">
                      {{ 'errors.is_required' | translate: {field: 'new_password' | translate} }}</label>
                    <ng-container
                      *ngIf="form.newPassword.errors.minlength || form.newPassword.errors.lowerCase || form.newPassword.errors.upperCase || form.newPassword.errors.digit || form.newPassword.errors.specialChar">
                      <label class="text-danger mb-0 me-2 text-end fs-10 fw-bold">
                        {{ 'password_criteria.invalid' | translate }}
                        <span
                          *ngIf="form.newPassword.errors.lowerCase">, 1 {{ 'password_criteria.lowercase' | translate }}</span>
                        <span
                          *ngIf="form.newPassword.errors.upperCase">, 1 {{ 'password_criteria.uppercase' | translate }}</span>
                        <span
                          *ngIf="form.newPassword.errors.digit">, 1 {{ 'password_criteria.digit' | translate }}</span>
                        <span
                          *ngIf="form.newPassword.errors.specialChar">, 1 {{ 'password_criteria.special_character' | translate }}</span>
                        <span
                          *ngIf="form.newPassword.errors.minlength">, {{ 'password_criteria.minimum' | translate: {count: 8} }}</span>
                      </label>
                    </ng-container>
                  </ng-container>
                </div>
                <div class="position-relative">
                  <input [type]="showPassword ? 'text' : 'password'" formControlName="newPassword"
                         class="form-control br-16"
                         placeholder="{{'enter_your_field' | translate: {field: 'new_password' | translate} }}">
                  <span class="cursor-pointer eye-cond">
                    <img [ngClass]="{'d-none': showPassword}" (click)="showPassword = true"
                         src="assets/images/icons/open-eye-icon.svg" alt="">
                    <img [ngClass]="{'d-none': !showPassword}" (click)="showPassword = false"
                         src="assets/images/icons/close-eye-icon.svg" alt="">
                </span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="position-relative">
                <div class="d-flex justify-content-between">
                  <label class="mb-0 ms-2 fs-12 fw-bold">{{'repeat_password' | translate}} *</label>
                  <ng-container
                    *ngIf="(form.confirmNewPassword.dirty || form.confirmNewPassword.touched) && form.confirmNewPassword.errors">
                    <label *ngIf="form.confirmNewPassword.errors.required"
                           class="text-danger mb-0 me-2 text-end fs-10 fw-bold">
                      {{ 'errors.is_required' | translate: {field: 'repeat_password' | translate} }}</label>
                    <label *ngIf="form.confirmNewPassword.errors.confirmPasswordValidator"
                           class="text-danger mb-0 me-2 text-end fs-10 fw-bold">
                      {{ 'password_criteria.no_match' | translate }}
                    </label>
                  </ng-container>
                </div>
                <div class="position-relative">
                  <input [type]="confirmShowPassword ? 'text' : 'password'" formControlName="confirmNewPassword"
                         class="form-control br-16"
                         placeholder="{{'repeat_password' | translate}}">
                  <span class="cursor-pointer eye-cond">
                    <img [ngClass]="{'d-none': confirmShowPassword}" (click)="confirmShowPassword = true"
                         src="assets/images/icons/open-eye-icon.svg" alt="">
                    <img [ngClass]="{'d-none': !confirmShowPassword}" (click)="confirmShowPassword = false"
                         src="assets/images/icons/close-eye-icon.svg" alt="">
                </span>
                </div>
              </div>
            </div>
            <button type="submit" class="mt-4 btn-action w-100 p-14"
                    [ngClass]="{'btn-disabled': resetPasswordForm.invalid, 'btn-action': resetPasswordForm.valid}">
              {{'create_password' | translate}}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
