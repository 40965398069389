import {Component, ElementRef, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {select, Store} from "@ngrx/store";
import {AuthState, getAuthError, getAuthSuccess, ResetAuthState, SendOtp, ValidateOtp} from "@kwot/auth";
import {Subject, takeUntil} from "rxjs";
import {APP_CONFIG, LocalstorageService} from "@kwot/app-config";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";

@Component({
  selector: 'kwot-validate-otp',
  templateUrl: './validate-otp.component.html',
  styleUrls: ['./validate-otp.component.scss']
})
export class ValidateOtpComponent implements OnInit, OnDestroy {

  submitted = false;
  validateForm: UntypedFormGroup;
  unsubscriber = new Subject();
  phoneNumber: string = 'XXX XXX XXX';
  countryCode: string = '';
  timer: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authStore: Store<AuthState>,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private router: Router,
    @Inject(APP_CONFIG) public appConfig: any,
    private localStorageService: LocalstorageService
  ) {
    this.timer = 0;
    this.phoneNumber = this.localStorageService.updateRegisterNumber('get');
    this.countryCode = this.localStorageService.updateCountryCode('get');
    this.authStore.dispatch(ResetAuthState({params: {error: '', success: ''}}));
    this.subscribeToStore();
  }

  subscribeToStore() {
    this.authStore.pipe(select(getAuthSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          this.toastr.success(this.translateService.instant('phone_verified_successfully'));
          const redirectUrl = this.localStorageService.updateRedirectUrl('get');
          this.localStorageService.updateRegisterNumber('remove');
          this.localStorageService.updateRedirectUrl('remove');
          this.localStorageService.updateCountryCode('remove');
          this.localStorageService.updateCommonKey('remove');
          let user = this.localStorageService.updateUserKey('get');
          if (!user) {
            user = this.localStorageService.updateRegisterUser('get');
          }
          if (user && user.isDeleted) {
            this.router.navigate(['/', this.appConfig.type, 'reset-password']);
            return;
          }
          if (redirectUrl) {
            const user = this.localStorageService.updateUserKey('get');
            if (user) {
              user.isPhoneVerified = true;
              this.localStorageService.updateUserKey('store', user);
            }
            this.router.navigate(['/', this.appConfig.type, redirectUrl]);
          } else {
            if (this.appConfig.type === "user") {
              this.router.navigate(['/', this.appConfig.type, 'home']);
              return
            }
            this.router.navigate(['/', this.appConfig.type]);
          }
        }
      })
    this.authStore.pipe(select(getAuthError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error && error === '---IGNORE---') {
          this.toastr.error(this.translateService.instant('invalid_otp'))
        }
      })
  }

  startTimer() {
    this.timer = 0;
    const timer = setInterval(() => {
      if (this.timer === 0) {
        clearInterval(timer);
      }
      this.timer = this.timer - 1;
    }, 1000);
  }

  changeField(event: any, nextField: ElementRef | any = null) {
    if (event.target.value) {
      if (nextField) {
        nextField.focus();
      }
    }
  }

  ngOnInit(): void {
    this.startTimer();
    this.validateForm = this.formBuilder.group({
      otp1: ['', [Validators.required, Validators.pattern(new RegExp(/[0-9]/))]],
      otp2: ['', [Validators.required, Validators.pattern(new RegExp(/[0-9]/))]],
      otp3: ['', [Validators.required, Validators.pattern(new RegExp(/[0-9]/))]],
      otp4: ['', [Validators.required, Validators.pattern(new RegExp(/[0-9]/))]],
      otp5: ['', [Validators.required, Validators.pattern(new RegExp(/[0-9]/))]]
    })
  }

  get form() {
    return this.validateForm.controls;
  }

  submitForm() {
    this.submitted = true;

    if (this.validateForm.invalid) {
      return;
    }

    const formValues = {...this.validateForm.value};
    const otp: any = {
      otp: formValues.otp1.toString().trim() + formValues.otp2.toString().trim() + formValues.otp3.toString().trim() + formValues.otp4.toString().trim() + formValues.otp5.toString().trim()
    };

    let userType = this.appConfig.type;
    if (this.localStorageService.updateRedirectUrl('get') && !this.localStorageService.updateUserKey('get')) {
      userType = 'admin';
      otp.phone = this.phoneNumber;
    }
    this.authStore.dispatch(ValidateOtp({otp, userType, ignoreClear: !!this.localStorageService.updateUserKey('get')}));
  }

  resendCode() {
    if (this.timer <= 0) {
      this.startTimer();
      this.authStore.dispatch(SendOtp({countryCode: this.countryCode, phone: this.phoneNumber}))
    }
  }

  goLogin() {
    this.authStore.dispatch(ResetAuthState({params: {user: null}}));
    this.localStorageService.clearAllLocalStorage();
    this.router.navigate(['/', this.appConfig.type, 'login']);
    return;
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
  }

}
