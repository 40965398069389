import {CreateWishlist} from './components/create-wishlist/create-wishlist.component';
import {PopupComponent} from './components/popup/popup.component';
import {RouterModule} from '@angular/router';
import {FooterComponent} from './components/footer/footer.component';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {BootstrapModule} from "@kwot/bootstrap";
import {TranslateLoader, TranslateModule, TranslateService, TranslateStore} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {CustomSelectComponent} from './components/custom-select/custom-select.component';
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SlugifyPipe} from './pipes/slugify.pipe';
import {LazyLoadImageModule} from "ng-lazyload-image";
import {CustomModalComponent} from './components/custom-modal/custom-modal.component';
import {ColorPickerDirective} from "./directives/colorPicker.directive";
import {NumberOnlyDirective} from "./directives/numberOnly.directive";
import {NoDecimalDirective} from "./directives/noDecimal.directive";
import {PascalCasePipe} from "./pipes/pascalCase.pipe";
import {SwiperModule} from 'swiper/angular';
import {MapForSelectPipe} from "./pipes/mapForSelect.pipe";
import {DateFormatPipe} from "./pipes/date-format.pipe";
import {ReplacePipe} from "./pipes/replace.pipe";
import {InputRefDirective} from "./directives/inputRef.directive";
import {StoreModule} from "@ngrx/store";
import * as fromAuth from "./+state/shared.reducer";
import {EffectsModule} from "@ngrx/effects";
import {SharedEffects} from "./+state/shared.effects";
import {ConvertToTreePipe} from "./pipes/convert-to-tree.pipe";
import {ImageCropperComponent} from './components/image-cropper/image-cropper.component';
import {ImageCropperModule} from "ngx-image-cropper";
import {CurrencyConvertPipe} from "./pipes/currencyConvert.pipe";
import {FormTouchedDirective} from "./directives/formTouched.directive";
import {CustomMeasurementComponent} from "./components/custom-mesurement/custom-measurement.component";
import {CardTwoComponent} from "./components/card-two/card-two.component";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    BootstrapModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      defaultLanguage: 'en'
    }),
    LazyLoadImageModule,
    SwiperModule,
    RouterModule,
    StoreModule.forFeature('shared', fromAuth.reducer),
    EffectsModule.forFeature([SharedEffects]),
    ImageCropperModule
  ],
  declarations: [
    CustomSelectComponent,
    SlugifyPipe,
    CustomModalComponent,
    ColorPickerDirective,
    NumberOnlyDirective,
    NoDecimalDirective,
    PascalCasePipe,
    FooterComponent,
    MapForSelectPipe,
    PopupComponent,
    CreateWishlist,
    DateFormatPipe,
    ReplacePipe,
    InputRefDirective,
    ConvertToTreePipe,
    ImageCropperComponent,
    CurrencyConvertPipe,
    FormTouchedDirective,
    CustomMeasurementComponent,
    CardTwoComponent,
  ],
  exports: [
    TranslateModule,
    BootstrapModule,
    CustomSelectComponent,
    SlugifyPipe,
    LazyLoadImageModule,
    CustomModalComponent,
    ColorPickerDirective,
    NumberOnlyDirective,
    NoDecimalDirective,
    FooterComponent,
    PascalCasePipe,
    MapForSelectPipe,
    PopupComponent,
    CreateWishlist,
    DateFormatPipe,
    ReplacePipe,
    InputRefDirective,
    ConvertToTreePipe,
    CurrencyConvertPipe,
    FormTouchedDirective,
    CustomMeasurementComponent,
    CardTwoComponent,
  ],
  providers: [
    TranslateStore,
    {
      provide: APP_INITIALIZER,
      deps: [TranslateService],
      multi: true,
      useFactory: (translate: TranslateService) => {
        return () => {
          translate.setDefaultLang('en');
          return translate.use('en');
        };
      }
    },
    CurrencyPipe,
    CurrencyConvertPipe
  ]
})
export class SharedModule {
}
