import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {RouterModule, Routes} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_CONFIG, ToastComponent} from '@kwot/app-config';
import {environment} from '../environments/environment';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpAuthInterceptService} from '@kwot/app-config';
import {ToastrModule} from 'ngx-toastr';
import {NoAuthGuard} from './guards/no-auth.guard';
import {MenuComponent} from './components/menu/menu.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {SharedModule} from '@kwot/shared';
import {NgApexchartsModule} from 'ng-apexcharts';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SellerDeactivateComponent} from './_components/seller-deactivate/seller-deactivate.component';
import {OrdersListComponent} from './components/orders-list/orders-list.component';
import {AuthGuard} from './guards/auth.guard';
import * as fromAdmin from './+state/admin.reducer';
import {AdminEffects} from './+state/admin.effects';
import {FinanceComponent} from './components/finance/finance.component';
import {ReviewsComponent} from './components/reviews/reviews.component';
import {ReviewInfoComponent} from './components/review-info/review-info.component';
import {AdminVendorSharedModule} from "@kwot/admin-vendor-shared";

const routes: Routes = [
  {path: '', redirectTo: 'admin/dashboard', pathMatch: 'full'},
  {
    path: 'admin',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@kwot/auth').then((module) => {
            module.AuthModule.environment = environment;
            return module.AuthModule;
          }),
        canActivate: [NoAuthGuard],
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'buyers',
        loadChildren: () =>
          import('./modules/buyer/buyer.module').then(
            (module) => module.BuyerModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'sellers',
        loadChildren: () =>
          import('./modules/seller/seller.module').then(
            (module) => module.SellerModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'orders',
        component: OrdersListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'finance',
        component: FinanceComponent,
        canActivate: [AuthGuard],
      },
      {
        path: '',
        loadChildren: () =>
          import('./modules/products/products.module').then(
            (module) => module.ProductsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'orders',
        loadChildren: () =>
          import('@kwot/orders').then((module) => module.OrdersModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'categories',
        loadChildren: () =>
          import('./modules/categories/categories.module').then(
            (module) => module.CategoriesModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: '',
        loadChildren: () =>
          import('@kwot/user-profile').then(
            (module) => module.UserProfileModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'site-settings',
        loadChildren: () =>
          import('./modules/site-settings/site-settings.module').then(
            (module) => module.SiteSettingsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: '',
        loadChildren: () =>
          import('./modules/audits/audits.module').then(
            (module) => module.AuditsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'reviews',
        component: ReviewsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'currency-rate',
        loadChildren: () =>
          import('./modules/currency/currency.module').then(
            (module) => module.CurrencyModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'shipping-cost',
        loadChildren: () =>
          import('./modules/shipping/shipping.module').then(
            (module) => module.ShippingModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: '',
        loadChildren: () =>
          import('./modules/policy/policy.module').then(
            (module) => module.PolicyModule
          ),
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  declarations: [
    AppComponent,
    ToastComponent,
    MenuComponent,
    DashboardComponent,
    SellerDeactivateComponent,
    OrdersListComponent,
    FinanceComponent,
    ReviewsComponent,
    ReviewInfoComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AdminVendorSharedModule,
    ToastrModule.forRoot({
      closeButton: true,
      maxOpened: 2,
      autoDismiss: true,
      preventDuplicates: true,
      toastComponent: ToastComponent,
      toastClass: 'ngx-toastr custom-toast',
    }),
    RouterModule.forRoot(routes, {initialNavigation: 'enabledBlocking'}),
    HttpClientModule,
    StoreModule.forRoot({admin: fromAdmin.reducer}, {
      metaReducers: !environment.production ? [] : [],
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true,
      },
    }),
    EffectsModule.forRoot([AdminEffects]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    NgApexchartsModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  providers: [
    {provide: APP_CONFIG, useValue: environment},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthInterceptService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
