import {NgModule} from '@angular/core';

// Models
export {User, PaginatedUsers} from './models/User'
export {SocialUser} from './models/SocialUser'
export {Store, PaginatedStores, WalletDetails, PayoutDetails, PaginatedAuditLog, PaginatedRevisions, Policy} from './models/Store'
export {AdminDashboard} from './models/AdminDashboard';
export {Order, OrderItems, PaginatedOrders} from './models/Order';
export {Categories, PaginatedCategories, DynamicField} from './models/Categories';
export {Product, PaginatedProducts} from './models/Product';
export {Colors} from './models/Colors';
export {Contact , PaginatedContacts} from './models/Contact';
export {Materials} from './models/Materials';
export {Address} from './models/Address';
export {Notifications} from './models/Notifications';
export {CustomPackages} from './models/CustomPackages';
export {PaginatedSellers} from './models/Sellers';
export {PaginatedLogNotes} from './models/LogNote';
export {CompanyProfile, CompanyAlbum, ProductCapacity, QualityManagement} from './models/CompanyProfile';
export {CurrencyData} from './models/CurrencyData';

// Constants
export {OrderStatusText, OrderStatus, ReturnOrderStatus, ReturnOrderStatusText, OrderStatusForFilterText} from "./consts/OrderStatus";
export {ProfileStatus, ProfileStatusText} from "./consts/ProfileStatus";
export {countryCurrency, countryStates} from "./consts/CountryCurrency";
export {countryCodes} from "./consts/CountryCodes";
export {defaultFilters} from "./consts/DefaultFilters";
export {currencyISOCodes} from "./consts/CurrencyISOCodes";
export {measurementsList} from "./consts/MeasurementsList";
export {BusinessType} from "./consts/Company";

// Common functions
export {generateCategoryTree, generateCategoryChilds} from "./utils/CommonFunction";

@NgModule({
  imports: [],
})
export class DataModelsModule {
}
