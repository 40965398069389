import {Component, Inject, OnInit} from '@angular/core';
import {ResetAuthState, VerifyData} from "@kwot/auth";
import {Store} from "@ngrx/store";
import {AuthState} from "@kwot/auth";
import {APP_CONFIG, LocalstorageService} from "@kwot/app-config";
import {Router} from "@angular/router";

@Component({
  selector: 'kwot-reactivate-account',
  templateUrl: './reactivate-account.component.html',
  styleUrls: ['./reactivate-account.component.scss']
})
export class ReactivateAccountComponent implements OnInit {
  reactiveUser: any;

  constructor(
    private authStore: Store<AuthState>,
    @Inject(APP_CONFIG) public appConfig: any,
    private ls: LocalstorageService,
    private router: Router
  ) {
    this.reactiveUser = this.ls.updateUserKey('get');
  }

  ngOnInit(): void {
  }

  goLogin() {
    this.authStore.dispatch(ResetAuthState({params: {user: null}}));
    this.ls.clearAllLocalStorage();
    this.router.navigate(['/', this.appConfig.type, 'login']);
    return;
  }

  goRegister() {
    this.authStore.dispatch(ResetAuthState({params: {user: null}}));
    this.ls.clearAllLocalStorage();
    this.router.navigate(['/', this.appConfig.type, 'register']);
    return;
  }

  verifyData() {
    if (this.reactiveUser.email) {
      this.authStore.dispatch(VerifyData({
        params: {
          redirect_url: `${window.location.origin}/${this.appConfig.type}/verify-email`
        }
      }));
      this.router.navigate(['/', this.appConfig.type, 'email-verification']);
    } else {
      this.ls.updateCommonKey('store', {redirect: false});
      this.ls.updateRegisterNumber('store', this.reactiveUser.phone || '');
      this.ls.updateCountryCode('store', this.reactiveUser.phoneCountry || '');
      this.ls.updateRegisterUser('store', this.reactiveUser);
      this.router.navigate(['/', this.appConfig.type, 'validate-otp'])
    }
  }
}
