import {Injectable} from '@angular/core';
import {CurrencyData} from "@kwot/data-models";

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  // 'in-india'
  validRates = ['ng-nigeria'];

  getCurrencyCodeAndSymbolAndAmount(currencyData: CurrencyData, input: number) {
    let currencySymbol = '$';
    let currencyCode = 'USD';
    let amount = Number(input);
    let country = `${currencyData.location.countryCode?.toLowerCase()}-${currencyData.location.countryName?.toLowerCase()}`;
    if (this.validRates.includes(country)) {
      currencySymbol = currencyData.location.currencySymbol;
      currencyCode = currencyData.location.currencyCode;
      amount = Number(input) * currencyData.rates[currencyData.location.currencyCode]['value'];
    }
    return {currencySymbol, currencyCode, amount};
  }

  getCurrencyCodeAndSymbolAndAmountWithRate(currencyData: CurrencyData) {
    let rate = 1;
    let currencyCode = 'USD';
    let country = `${currencyData.location.countryCode?.toLowerCase()}-${currencyData.location.countryName?.toLowerCase()}`;
    if (this.validRates.includes(country)) {
      currencyCode = currencyData.location.currencyCode;
      rate = currencyData.rates[currencyData.location.currencyCode]['value'];
    }
    return {rate, currencyCode};
  }
}
