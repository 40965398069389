import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {LoaderService} from "@kwot/app-config";
import {VendorBuyerService} from "../services/vendorBuyer.service";
import {
  addContactData,
  addContactDataSuccess,
  addContactDataError,
  GetPolicyData, GetPolicyDataSuccess, GetPolicyDataError
} from "./vendor-buyer.actions";
import {catchError, map, of, switchMap} from "rxjs";

@Injectable()
export class VendorBuyerEffects {
  constructor(
    private actions$: Actions,
    private loaderService: LoaderService,
    private vendorBuyerService: VendorBuyerService
  ) {
  }

  addContactUsData$ = createEffect(() => this.actions$.pipe(
    ofType(addContactData),
    switchMap((action) => {
      this.loaderService.show();
      return this.vendorBuyerService.contactUs(action.body).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return addContactDataSuccess();
          }
          return addContactDataError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(addContactDataError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  getPolicyData = createEffect(() => this.actions$.pipe(
    ofType(GetPolicyData),
    switchMap((action) => {
      return this.vendorBuyerService.getPolicyData(action.params).pipe(
        map((resp: any) => {
          if (resp.status) {
            return GetPolicyDataSuccess({policy: resp.data});
          }
          return GetPolicyDataError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          return of(GetPolicyDataError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));
}
