import {Categories} from "@kwot/data-models";
import {TreeviewItem} from "ngx-treeview";

export function generateCategoryTree(categories: Categories[], disableCategory: string = '', rawFormat = false, getSingles = true) {
  const finalCategories: any[] = [];

  let maxOrder = Math.max(...categories.map(item => item.order).filter(t => t));
  let roots = categories.filter((item: any) => item.isRoot);
  roots.sort((a: any, b: any) => (a.order || (maxOrder + 1)) - (b.order || (maxOrder + 1)));
  if (roots.length > 0) {
    roots.forEach((item: any) => {
      let childItem: any = {
        text: item.name,
        value: rawFormat ? item : item._id,
        children: [],
        disabled: (disableCategory && disableCategory === item._id),
        isSelected: false
      };
      const childes: any = findChildes(categories, item, disableCategory, rawFormat);
      childItem.children.push(...childes);
      finalCategories.push(rawFormat ? childItem : new TreeviewItem(childItem));
    })
  }
  if (getSingles) {
    let nonRootCategories = categories.filter((item: any) => !item.isRoot && !item.parentId);
    if (nonRootCategories.length > 0) {
      nonRootCategories.forEach((item: any) => {
        let childItem: any = {
          text: item.name,
          value: rawFormat ? item : item._id,
          children: [],
          disabled: (disableCategory && disableCategory === item._id),
          isSelected: false
        };
        const childes: any = findChildes(categories, item, disableCategory, rawFormat);
        childItem.children.push(...childes);
        finalCategories.push(rawFormat ? childItem : new TreeviewItem(childItem));
      })
    }
  }

  return finalCategories;
}

function findChildes(categories: Categories[], currentCategory: Categories, disableCategory: string, rawFormat: boolean) {
  const items: any = [];
  let findChild = categories.filter((t: any) => t._id !== currentCategory._id && t.parentId && t.parentId._id === currentCategory._id);
  if (findChild.length > 0) {
    findChild.forEach((child: any) => {
      let values: any = {
        text: child.name,
        value: rawFormat ? child : child._id,
        disabled: (disableCategory && disableCategory === child._id),
        isSelected: false,
        children: [],
      };
      const childes: any = findChildes(categories, child, disableCategory, rawFormat);
      values.children.push(...childes);
      items.push(values)
    })
  }
  return items;
}

export function generateCategoryChilds(categories: Categories[], parentCategory: Categories) {
  let childes: any = findChildes(categories, parentCategory, '', true);
  childes = formatChildes(childes);
  return childes;
}

function formatChildes(chides: any[]) {
  let finalData: any[] = [];

  function pushChildes(items: any[]) {
    items.forEach(item => {
      finalData.push(item.value._id);
      if (item.children.length > 0) {
        pushChildes(item.children);
      }
    })
  }

  pushChildes(chides);
  return finalData;
}
