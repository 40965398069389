import {AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {LoaderService, LocalstorageService} from "@kwot/app-config";
import {Observable, Subject, takeUntil} from "rxjs";
import {select, Store} from "@ngrx/store";
import {AuthState, getLoggedInUser} from "@kwot/auth";
import {getAdminError, getAdminSuccess} from "./+state/admin.selectors";
import {AdminState} from "./+state/admin.reducer";
import {ToastrService} from "ngx-toastr";
import {User} from "@kwot/data-models";
import {RouteConfigLoadEnd, RouteConfigLoadStart, Router} from "@angular/router";

@Component({
  selector: 'kwot-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {
  loader$: Observable<boolean>;
  uploader$: Observable<boolean>;
  progress$: Observable<number>;
  currentUser: User;
  unsubscriber = new Subject();
  loadingRouteConfig: boolean;
  isB2B: boolean = false

  constructor(
    private loaderService: LoaderService,
    private authStore: Store<AuthState>,
    private adminStore: Store<AdminState>,
    private toastr: ToastrService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private localStorageService: LocalstorageService
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof RouteConfigLoadStart) {
        this.loadingRouteConfig = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loadingRouteConfig = false;
      }
    })
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngOnInit() {
    this.isB2B = this.localStorageService.updateIsB2B('get');
    this.loader$ = this.loaderService.loader$;
    this.uploader$ = this.loaderService.uploader$;
    this.progress$ = this.loaderService.progress$;
    this.authStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(currentUser => {
        const validateOTP = this.localStorageService.updateRegisterNumber('get');
        if (!validateOTP && currentUser) {
          this.currentUser = currentUser;
        } else {
          this.currentUser = null;
        }
      })

    this.adminStore.pipe(select(getAdminError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error && error !== '---IGNORE---') {
          this.toastr.error(error);
        }
      })

    this.adminStore.pipe(select(getAdminSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success && success !== '---IGNORE---') {
          this.toastr.success(success);
        }
      })
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
  }
}
