<div class="auth-component">
<!--  <span id="close">-->
<!--    <img class="" [routerLink]="['/', appConfig.type, 'login']" src="assets/images/icons/Cross.svg"-->
<!--         alt=""></span>-->
  <img class="bg-right--img d-none d-md-block" src="assets/images/bg/auth-Bg.png" alt="">
  <img class="bg-right--img d-md-none" src="assets/images/bg/auth-reg-mobile-Bg.png" alt="">
  <div class="container">
    <div class="row">
      <div class="col-xl-4 col-md-5 pt-56 pt-md-36">
        <div class="action--form">
          <div class="logo pb-50 text-center">
            <img class="w-50" src="assets/images/Logo.svg" alt="">
          </div>

          <form class="login-form" (ngSubmit)="!registerForm.invalid && submitForm()" novalidate
                [formGroup]="registerForm">
            <h1 class="register-title text-center" *ngIf="appConfig.type !== 'vendor'">{{'register' | translate}}</h1>
            <h1 class="register-title text-center"
                *ngIf="appConfig.type === 'vendor'">{{'register_as_seller' | translate}}</h1>
            <div class="form-group">
              <div class="d-flex justify-content-between">
                <label class="mb-0 ms-2 me-auto fs-12 fw-bold">{{'first_name' | translate}}</label>
                <ng-container *ngIf="(form.firstName.dirty || form.firstName.touched) && form.firstName.errors">
                  <label *ngIf="form.firstName.errors.required" class="text-danger mb-0 me-2  fs-10 fw-bold">
                    {{ 'errors.is_required' | translate: {field: 'first_name' | translate} }}</label>
                </ng-container>
              </div>
              <input type="text" class="form-control br-16" formControlName="firstName" inputRef
                     placeholder="{{'placeholder.type' | translate: {field: 'first_name' | translate | lowercase} }}">

            </div>
            <div class="form-group">
              <div class="d-flex justify-content-between">
                <label class="mb-0 ms-2 fs-12 fw-bold">{{'last_name' | translate}}</label>
                <ng-container *ngIf="(form.lastName.dirty || form.lastName.touched) && form.lastName.errors">
                  <label *ngIf="form.lastName.errors.required" class="text-danger mb-0 me-2 fs-10 fw-bold">
                    {{ 'errors.is_required' | translate: {field: 'last_name' | translate} }}</label>
                </ng-container>
              </div>
              <input type="text" class="form-control br-16" formControlName="lastName" inputRef
                     placeholder="{{'placeholder.type' | translate: {field: 'last_name' | translate | lowercase} }}">
            </div>
            <kwot-email-phone [formGroup]="registerForm"
                              [isRegisterWithSocialAccount]="isRegisterWithSocialAccount"
                              [isRegisterWithPhoneNumber]="isRegisterWithPhoneNumber" type="registration"
                              (onTypeChange)="changeLoginType($event)"></kwot-email-phone>
            <div class="form-group" *ngIf="!isRegisterWithSocialAccount">
              <div class="position-relative">
                <div class="d-flex justify-content-between">
                  <label class="mb-0 ms-2 fs-12 fw-bold">{{'password' | translate}}</label>
                  <ng-container *ngIf="(form.password.dirty || form.password.touched) && form.password.errors">
                    <label *ngIf="form.password.errors.required"
                           class="text-danger mb-0 me-2 text-end fs-10 fw-bold">
                      {{ 'errors.is_required' | translate: {field: 'password' | translate} }}</label>
                    <ng-container
                      *ngIf="form.password.errors.minlength || form.password.errors.lowerCase || form.password.errors.upperCase || form.password.errors.digit || form.password.errors.specialChar">
                      <label class="text-danger mb-0 me-2 text-end fs-10 fw-bold">
                        {{ 'password_criteria.invalid' | translate }}
                        <span
                          *ngIf="form.password.errors.lowerCase">, 1 {{ 'password_criteria.lowercase' | translate }}</span>
                        <span
                          *ngIf="form.password.errors.upperCase">, 1 {{ 'password_criteria.uppercase' | translate }}</span>
                        <span *ngIf="form.password.errors.digit">, 1 {{ 'password_criteria.digit' | translate }}</span>
                        <span
                          *ngIf="form.password.errors.specialChar">, 1 {{ 'password_criteria.special_character' | translate }}</span>
                        <span
                          *ngIf="form.password.errors.minlength">, {{ 'password_criteria.minimum' | translate: {count: 8} }}</span>
                      </label>
                    </ng-container>
                  </ng-container>
                </div>
                <div class="position-relative">
                  <input [type]="showPassword ? 'text' : 'password'" formControlName="password"
                         class="form-control br-16"
                         placeholder="{{'enter_your_field' | translate: {field: 'password' | translate} }}">
                  <span class="cursor-pointer eye-cond">
                    <img [ngClass]="{'d-none': showPassword}" (click)="showPassword = true"
                         src="assets/images/icons/open-eye-icon.svg" alt="">
                    <img [ngClass]="{'d-none': !showPassword}" (click)="showPassword = false"
                         src="assets/images/icons/close-eye-icon.svg" alt="">
                </span>
                </div>
              </div>
            </div>
            <div class="form-group" *ngIf="appConfig.type === 'admin'">
              <div class="d-flex justify-content-between">
                <label class="mb-0 ms-2 fs-12 fw-bold" for="roles">{{'role' | translate}}</label>
                <ng-container *ngIf="(form.role.dirty || form.role.touched) && form.role.errors">
                  <label *ngIf="form.role.errors.required" class="text-danger mb-0 me-2 fs-10 fw-bold">
                    {{ 'errors.is_required' | translate: {field: 'role' | translate} }}</label>
                </ng-container>
              </div>
              <div class="position-relative">
                <img src="assets/images/icons/down-arrow.svg" alt="" class="select-arrow">
                <select name="roles" id="roles" formControlName="role"
                        class="form-control form-select fs-12 br-16 fw-medium">
                  <option disabled
                          value="">{{'choose_your_field' | translate: {field: 'role' | translate | lowercase} }}</option>
                  <option *ngFor="let role of roles" [value]="role.value">{{role.name}}</option>
                </select>
              </div>

            </div>
            <div class="text-start pt-2 d-flex align-items-center">
              <label class="action-switch-green-btn me-2 m-0">
                <input type="checkbox" formControlName="terms">
                <span class="slider round"></span>
              </label>

              <div class="fs-16 fw-bold terms-of-use text-center">{{'i_accept' | translate}}
                <span class="cursor-pointer"
                      (click)="showPrivacyTerms('privacy')">{{'privacy_policy'| translate}}</span>
                {{'and'| translate}}
                <span class="cursor-pointer" (click)="showPrivacyTerms('terms')">{{'terms_of_use'| translate}}</span>
              </div>
            </div>
            <ng-container *ngIf="(form.terms.dirty || form.terms.touched) && form.terms.errors">
              <label *ngIf="form.terms.errors.required" class="text-danger mb-0 ms-2 fs-10 fw-bold">
                {{'errors.accept_terms_condition' | translate}}</label>
            </ng-container>
            <ng-container *ngIf="appConfig.type === 'vendor'">
              <div class="mt-4">
                <re-captcha formControlName="recaptchaChecked"></re-captcha>
              </div>
            </ng-container>
            <button type="submit" class="my-4 btn-action w-100 p-14"
                    [ngClass]="{'btn-disabled': registerForm.invalid, 'btn-action': registerForm.valid}">{{'register' | translate}}
            </button>
          </form>
          <ng-container *ngIf="appConfig.type === 'vendor' || appConfig.type === 'user'">
            <div class="col-md-12 fs-14 fw-bold terms-of-use text-center">
              {{'continue_with' | translate}}
            </div>
            <div class="col-md-12 text-center mt-2">
              <img src="assets/images/icons/google-icon.svg" alt="" class="cursor-pointer" (click)="loginWithGoogle()">
            </div>
          </ng-container>
          <div class="mt-3">
            <div class="sign-in text-center">
              <p class="sign-in-link fw-bold">{{'already_have_account' | translate}} <a
                [routerLink]="['/', appConfig.type, 'login']">{{'sign_in' | translate}}</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
