import {Inject, Pipe, PipeTransform} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {APP_CONFIG, LocalstorageService} from "@kwot/app-config";
import {CommonService} from "../services/common.service";
import {CurrencyPipe} from "@angular/common";
import * as moment from "moment";
import {Observable} from "rxjs";
import {countryCurrency, CurrencyData} from "@kwot/data-models";
import {select, Store} from "@ngrx/store";
import {SharedState} from "../+state/shared.reducer";
import {getCurrencyData} from "../+state/shared.selectors";
import {GetCurrencyData} from "../+state/shared.actions";
import {UtilService} from "../services/util.service";

@Pipe({
  name: 'currencyConvert'
})
export class CurrencyConvertPipe implements PipeTransform {
  IPAddressUrl = '';
  defaultConvertParams: any = {
    platformFee: 0,
    onlyPrice: false
  };
  currencyData: CurrencyData = null;

  constructor(
    private httpclient: HttpClient,
    private localStorageService: LocalstorageService,
    private currencyPipe: CurrencyPipe,
    private commonService: CommonService,
    private localstorageService: LocalstorageService,
    @Inject(APP_CONFIG) private appConfig: any,
    private sharedStore: Store<SharedState>,
    private utilService: UtilService
  ) {
    this.IPAddressUrl = this.appConfig.ip_api;
    if (this.appConfig.type === 'user') {
      this.sharedStore.pipe(select(getCurrencyData))
        .subscribe(currencyData => {
          if (currencyData) {
            this.currencyData = currencyData;
            localStorage.removeItem('stopCode');
          }
        })
      this.getCurrencyRateAndData();
    }
  }

  getCurrencyRateAndData() {
    if (!localStorage.getItem('stopCode') && !this.currencyData) {
      this.sharedStore.dispatch(GetCurrencyData());
      localStorage.setItem('stopCode', 'true');
    }
  }

  transform(input: any, convertParameters: any = {}): Observable<any> {
    convertParameters = {...this.defaultConvertParams, ...convertParameters};
    return new Observable(observable => {
      this.setData(input, convertParameters, observable);
    })
  }

  setData(input: any, convertParameters: any, observable: any) {
    let finalAmount = Number(input);
    if (convertParameters.platformFee > 0) {
      let platformFee = (finalAmount * convertParameters.platformFee) / 100
      finalAmount = finalAmount + platformFee;
    }
    if (this.appConfig.type === 'user') {
      if (this.currencyData) {
        let currencyData = this.utilService.getCurrencyCodeAndSymbolAndAmount(this.currencyData, finalAmount);
        // let convertedAmount = convertParameters.ignoreConversion ? finalAmount : currencyData.amount;
        let convertedAmount = currencyData.amount;
        if (!convertParameters.onlyPrice) {
          observable.next(this.currencyPipe.transform(convertedAmount, currencyData.currencyCode, currencyData.currencySymbol));
        } else {
          observable.next(convertedAmount);
        }
      } else {
        setTimeout(() => {
          this.setData(input, convertParameters, observable);
        }, 1000);
      }
    } else {
      if (!convertParameters.onlyPrice) {
        observable.next(this.currencyPipe.transform(finalAmount, 'USD', '$'));
      } else {
        observable.next(finalAmount);
      }
    }
  }

  getCurrencyCodeAndSymbol(data: any, toCurrency: string) {
    let currencyCode: string = '';
    let currencySymbol: string = '';
    if (toCurrency) {
      let data = this.getDefaultValues(toCurrency);
      currencyCode = data.currencyCode;
      currencySymbol = data.currencySymbol;
      return {currencySymbol, currencyCode};
    }
    if (this.appConfig.type === 'user') {
      if (data.ipCurrency) {
        currencyCode = data.ipCurrency.code;
        currencySymbol = data.ipCurrency.symbol;
      } else {
        let data = this.getDefaultValues(toCurrency);
        currencyCode = data.currencyCode;
        currencySymbol = data.currencySymbol;
      }
    } else {
      if (this.appConfig.type === 'vendor') {
        let user = this.localstorageService.updateUserKey('get');
        if (user && user.currency) {
          let findCurrency = countryCurrency.find(item => item.currency.code === user.currency);
          if (findCurrency) {
            currencyCode = findCurrency.currency.code;
            currencySymbol = typeof findCurrency.currency.symbol === 'string' ? findCurrency.currency.symbol : findCurrency.currency.code;
          } else {
            let data = this.getDefaultValues(toCurrency);
            currencyCode = data.currencyCode;
            currencySymbol = data.currencySymbol;
          }
        } else {
          let data = this.getDefaultValues(toCurrency);
          currencyCode = data.currencyCode;
          currencySymbol = data.currencySymbol;
        }
      } else {
        let data = this.getDefaultValues(toCurrency);
        currencyCode = data.currencyCode;
        currencySymbol = data.currencySymbol;
      }
    }
    return {currencySymbol, currencyCode};
  }

  getDefaultValues(currency: string = 'USD') {
    if (!currency) {
      currency = 'USD';
    }
    let currencyCode: string = '';
    let currencySymbol: string = '';
    let findCurrency = countryCurrency.find(item => item.currency.code === currency);
    if (findCurrency) {
      currencyCode = findCurrency.currency.code;
      currencySymbol = typeof findCurrency.currency.symbol === 'string' ? findCurrency.currency.symbol : findCurrency.currency.code;
    }
    return {currencySymbol, currencyCode};
  }

  getCurrencyData() {
    const localeData = this.localStorageService.updateGeolocation('get');

    if (!localeData || !localeData.updatedAt) {
      if (!localStorage.getItem('stopCode')) {
        this.getIpLocation();
        localStorage.setItem('stopCode', 'true');
      }
    }

    if (localeData) {
      let differentInHour = moment().diff(moment(localeData.updatedAt), 'hours');
      if (differentInHour > 1) {
        if (!localStorage.getItem('stopCode')) {
          this.getIpLocation();
          localStorage.setItem('stopCode', 'true');
        }
      }
    }
  }

  getIpLocation() {
    // if (this.appConfig.type === 'user') {
    if (this.appConfig.type === 'IGNORE') {
      this.httpclient.get(this.IPAddressUrl).subscribe((response: any) => {
        this.getAllCurrencyData(response);
      }, error => {
        this.getAllCurrencyData();
      });
    } else {
      this.getAllCurrencyData();
    }
  }

  getAllCurrencyData(response: any = {}) {
    this.commonService.getConversationRates().subscribe((resp: any) => {
      if (resp.data) {
        let geoLocationData: any = {};
        geoLocationData.rates = resp.data.rates;
        geoLocationData.siteSettings = resp.data.siteSettings;
        geoLocationData.ipCurrency = response.currency || {
          "code": "USD",
          "name": "United States Dollar",
          "symbol": "$"
        };
        geoLocationData.updatedAt = new Date;
        this.localstorageService.updateGeolocation('store', geoLocationData);
      }
    });
  }

//   const data = this.localStorageService.updateGeolocation('get');
//   if (Number(input) > -1 && data) {
//   if (convertParameters.includePlatformFee && data.siteSettings?.platformFees && data.siteSettings?.platformFees > 0) {
//   let platformFee = (input * data.siteSettings?.platformFees) / 100
//   input = input + platformFee;
// }
// localStorage.removeItem('stopCode')
//
// // FORCE THE CURRENCY TO ONLY USD
//
// if (!convertParameters.onlyPrice) {
//   observable.next(this.currencyPipe.transform(input, 'USD', '$'));
// } else {
//   observable.next(input);
// }
// return;
//
// // DO NOT FORCE THE CURRENCY
//
// if (convertParameters.fromCurrency === convertParameters.toCurrency) {
//   let {currencySymbol, currencyCode} = this.getCurrencyCodeAndSymbol(data, convertParameters.toCurrency);
//   observable.next(this.currencyPipe.transform(input, currencyCode, currencySymbol));
// } else {
//   let {currencySymbol, currencyCode} = this.getCurrencyCodeAndSymbol(data, convertParameters.toCurrency);
//   let amount = Number(input).toFixed(2);
//   if (convertParameters.fromCurrency && convertParameters.fromCurrency !== 'IGNORE') {
//     // console.log({'fromCurrency': fromCurrency, 'toCurrency': toCurrency, 'currencyCode': currencyCode, 'symbol': currencySymbol});
//     const fromAmountToUsd = Number(input) / (convertParameters.fromRate ? convertParameters.fromRate : data.rates[convertParameters.fromCurrency]['value']);
//     let convertedAmount = fromAmountToUsd * (convertParameters.toRate ? convertParameters.toRate : data.rates[currencyCode]['value'])
//     amount = convertedAmount.toFixed(2);
//   }
//   if (!convertParameters.onlyPrice) {
//     observable.next(this.currencyPipe.transform(amount, currencyCode, currencySymbol));
//   } else {
//     observable.next(amount);
//   }
// }
// } else {
//   setTimeout(() => {
//     this.setData(input, convertParameters, observable);
//   }, 1000);
// }
}
