import {Component, Inject, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {Title} from "@angular/platform-browser";
import {select, Store} from "@ngrx/store";
import {getPolicyData} from "../../+state/vendor-buyer.selectors";
import {Subject, takeUntil} from "rxjs";
import {VendorBuyerState} from "../../+state/vendor-buyer.reducer";
import {GetPolicyData} from '../../+state/vendor-buyer.actions';
import {APP_CONFIG} from "@kwot/app-config";

@Component({
  selector: 'kwot-privacy-terms',
  templateUrl: './privacy-terms.component.html',
  styleUrls: ['./privacy-terms.component.scss']
})
export class PrivacyTermsComponent implements OnInit {

  activeTab: 'terms' | 'privacy' = 'terms';
  terms: string;
  privacy: string;
  showCrossIcon = true
  unsubscriber = new Subject();
  policyData: any;
  data: any;
  termsData: any;

  constructor(
    public bsModalRef: BsModalRef,
    private titleService: Title,
    private vendorBuyerState: Store<VendorBuyerState>,
    @Inject(APP_CONFIG) public appConfig: any
  ) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('Kwot - Amazing Africa. Now, delivered at your doorstep');
    let url = window.location.href;
    if (url.includes('privacy-policy')) {
      this.termsData = 'buyerPolicy'
      this.activeTab = "privacy"
      this.showCrossIcon = false;
    } else if (url.includes('terms-condition-seller')) {
      this.termsData = 'sellerTerms';
      this.activeTab = "terms"
      this.showCrossIcon = false;
    } else if (url.includes('terms-condition')) {
      this.termsData = 'buyerTerms';
      this.activeTab = "terms"
      this.showCrossIcon = false;
    }
    this.vendorBuyerState.dispatch(GetPolicyData({
      params: {
        terms: this.termsData
      }
    }))
    this.subscribeToStore();
  }

  subscribeToStore() {
    this.vendorBuyerState.pipe(select(getPolicyData))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(policies => {
        if (policies) {
          this.policyData = policies;
          this.terms = this.policyData.content;
          this.privacy = this.policyData.content;
        }
      })
  }

  getData(data: any) {
    this.activeTab = data;
    if (this.activeTab === 'terms') {
      this.termsData = this.appConfig.type === 'vendor' ? 'sellerTerms' : 'buyerTerms';
    } else {
      this.termsData = this.appConfig.type === 'vendor' ? 'sellerPolicy' : 'buyerPolicy';
    }
    this.vendorBuyerState.dispatch(GetPolicyData({
      params: {
        terms: this.termsData
      }
    }))
  }

}
