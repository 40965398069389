import {Component, Inject, OnInit} from '@angular/core';
import {ResetAuthState, VerifyData} from "@kwot/auth";
import {Store} from "@ngrx/store";
import {AuthState} from "@kwot/auth";
import {APP_CONFIG, LocalstorageService} from "@kwot/app-config";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'kwot-reset-verification',
  templateUrl: './reset-verification.component.html',
  styleUrls: ['./reset-verification.component.scss']
})
export class ResetVerificationComponent implements OnInit {
  email: string;
  type: string;

  constructor(
    private authStore: Store<AuthState>,
    @Inject(APP_CONFIG) public appConfig: any,
    private ls: LocalstorageService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {
    this.email = this.ls.updateUserKey('get').email
    this.type = this.activeRoute.snapshot.queryParams.type;
  }

  ngOnInit(): void {
  }

  goLogin() {
    this.authStore.dispatch(ResetAuthState({params: {user: null}}));
    this.ls.clearAllLocalStorage();
    this.router.navigate(['/', this.appConfig.type, 'login']);
    return;
  }

  verifyData() {
      this.authStore.dispatch(VerifyData({
        params: {
          redirect_url: `${window.location.origin}/${this.appConfig.type}/verify-email`
        }
      })
    )
  }
}
