import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {ToastrService} from "ngx-toastr";
import {select, Store} from "@ngrx/store";
import {AuthState, getAuthError, getAuthSuccess} from "@kwot/auth";

@Component({
  selector: 'kwot-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, OnDestroy {
  unsubscriber = new Subject();

  constructor(
    private toastr: ToastrService,
    private authStore: Store<AuthState>
  ) {
  }

  ngOnInit(): void {
    this.authStore.pipe(select(getAuthSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success && success !== '---IGNORE---') {
          this.toastr.success(success);
        }
      })
    this.authStore.pipe(select(getAuthError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error && error !== '---IGNORE---') {
          this.toastr.error(error);
        }
      })
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
  }


}
