import {Action, createReducer, on} from "@ngrx/store";
import {
  addContactData,
  addContactDataError,
  addContactDataSuccess,
  ResetVendorBuyerState,
  GetPolicyData, GetPolicyDataError, GetPolicyDataSuccess,
} from "./vendor-buyer.actions";
import {Policy} from "@kwot/data-models";

export interface VendorBuyerState {
  success: string;
  error: string;
  notifications: any;
  notificationSeen: any;
  policy: Policy;
}

const initVendorBuyerState: VendorBuyerState = {
  success: '', error: '', notifications: null, notificationSeen: null,
  policy: null
}

const vendorBuyerReducer = createReducer(initVendorBuyerState,
  on(addContactData, (state) => {
    return {
      ...state,
      success: '',
      error: '',
    };
  }),

  on(addContactDataSuccess, (state) => {
    return {
      ...state,
      success: '---IGNORE---',
      error: '',
    };
  }),

  on(addContactDataError, (state, {error}) => {
    return {
      ...state,
      success: '',
      error,
    };
  }),

  on(GetPolicyData, (state) => ({...state, policy: null, error: '', success: ''})),
  on(GetPolicyDataSuccess, (state, {policy}) => ({...state, policy, error: '', success: ''})),
  on(GetPolicyDataError, (state, {error}) => ({...state, error, success: ''})),

  on(ResetVendorBuyerState, (state, {params}) => ({
    ...state,
    ...params
  }))
)

export function reducer(state = initVendorBuyerState, action: Action) {
  return vendorBuyerReducer(state, action);
}
