<div [formGroup]="formGroup">
  <div class="form-group" [ngClass]="{'mb-0': !isRegisterWithSocialAccount}" *ngIf="!isRegisterWithPhoneNumber">
    <div class="d-flex justify-content-between">
      <label class="mb-0 ms-2 fs-12 fw-bold">{{'email' | translate}}</label>
      <ng-container *ngIf="(form.email.dirty || form.email.touched) && form.email.errors">
        <label *ngIf="form.email.errors.required" class="text-danger mb-0 me-2 fs-10 fw-bold">
          {{ 'errors.is_required' | translate: {field: 'email' | translate} }}</label>
        <label *ngIf="form.email.errors.pattern" class="text-danger mb-0 me-2 fs-10 fw-bold">
          {{ 'errors.enter_valid' | translate: {field: 'email_address' | translate} }}</label>
      </ng-container>
    </div>
    <input type="text" class="form-control br-16" formControlName="email"
           [readOnly]="isRegisterWithSocialAccount"
           placeholder="{{'placeholder.type' | translate: {field: 'email' | translate | lowercase } }}">
  </div>
  <div class="form-group mb-0" *ngIf="isRegisterWithPhoneNumber">
    <div class="d-flex justify-content-between">
      <label class="mb-0 ms-2 fs-12 fw-bold">{{'mobile_number' | translate}}</label>
      <ng-container *ngIf="form.phone.dirty && form.phone.touched && form.phone.errors">
        <label *ngIf="form.phone.errors.required" class="text-danger mb-0 me-2 fs-10 fw-bold">
          {{ 'errors.is_required' | translate: {field: 'mobile' | translate} }}</label>
        <label *ngIf="form.phone.errors.validatePhoneNumber"
               class="text-danger mb-0 ms-2 fs-10 fw-bold">
          {{ 'errors.invalid' | translate: {field: 'mobile_number' | translate | lowercase} }}</label>
      </ng-container>
    </div>
    <ngx-intl-tel-input
      class="phone-input-control"
      cssClass="phone-input form-control"
      formControlName="phone"
      [enablePlaceholder]="true"
      customPlaceholder="{{'placeholder.type' | translate: {field: 'mobile_number' | translate | lowercase} }}"
      [phoneValidation]="true"
      [numberFormat]="PhoneNumberFormat.International"
      [enableAutoCountrySelect]="true"
      [searchCountryFlag]="true"
      [selectFirstCountry]="false"
      [separateDialCode]="true"
      [selectedCountryISO]="CountryISO.Nigeria"
      [maxLength]="18"
      name="phone"
    >
    </ngx-intl-tel-input>
  </div>
</div>
<ng-container *ngIf="appConfig.type !== 'vendor' || type !== 'registration'">
  <div class="col-md-12 text-start type-switch fw-bold fs-14 ps-1 mt-1 mb-3"
       *ngIf="!isRegisterWithSocialAccount">
    <span class="cursor-pointer" (click)="changeLoginType(!isRegisterWithPhoneNumber)">
      {{isRegisterWithPhoneNumber ?
      ('use' | translate: {field: 'email' | translate | lowercase})
      : ('use' | translate: {field: 'mobile_number' | translate | lowercase})}}
    </span> {{ 'for_type' | translate: {type: ((type || 'registration') | translate)} }}
  </div>
</ng-container>
