import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {LoaderService, LocalstorageService} from "@kwot/app-config";
import {CommonService} from "../services/common.service";
import {
  GetCurrencyData, GetCurrencyDataError, GetCurrencyDataSuccess,
  SaveMeasurements, SaveMeasurementsError, SaveMeasurementsSuccess,
} from "./shared.actions";
import {catchError, map, of, switchMap} from "rxjs";

@Injectable()
export class SharedEffects {
  constructor(
    private actions$: Actions,
    private loaderService: LoaderService,
    private commonService: CommonService,
    private ls: LocalstorageService
  ) {
  }

  saveMeasurements$ = createEffect(() => this.actions$.pipe(
    ofType(SaveMeasurements),
    switchMap((action) => {
      this.loaderService.show();
      return this.commonService.saveMeasurements(action.body).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            let isGuest = false;
            let user = this.ls.updateUserKey('get');
            if (!user && action.allowGuest) {
              user = this.ls.updateGuestUserKey('get');
              isGuest = true;
            }
            if (user) {
              user.measurements = resp.data;
              if (isGuest) {
                this.ls.updateGuestUserKey('store', user);
              } else {
                this.ls.updateUserKey('store', user);
              }
            }
            return SaveMeasurementsSuccess({measurements: resp.data});
          }
          return SaveMeasurementsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(SaveMeasurementsError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ))

  getCurrencyData$ = createEffect(() => this.actions$.pipe(
    ofType(GetCurrencyData),
    switchMap((action) => {
      this.loaderService.show();
      return this.commonService.getConversationRates().pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return GetCurrencyDataSuccess({currencyData: resp.data});
          }
          return GetCurrencyDataError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetCurrencyDataError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ))
}
