import {createReducer, on, Action} from '@ngrx/store';
import {
  CheckAccountVerifyLink, CheckAccountVerifyLinkError, CheckAccountVerifyLinkSuccess,
  CheckResetPasswordLink, CheckResetPasswordLinkError, CheckResetPasswordLinkSuccess,
  ForgotPassword,
  ForgotPasswordError,
  ForgotPasswordSuccess,
  Login,
  LoginError,
  LoginSuccess, LogoutUser,
  Register,
  RegisterError,
  RegisterSuccess,
  ResetAuthState, ResetPassword, ResetPasswordError, ResetPasswordSuccess, SendOtp, SendOtpError, SendOtpSuccess,
  SignInWithSocial,
  SignInWithSocialSuccess,
  ValidateOtp,
  ValidateOtpError,
  ValidateOtpSuccess,
  VerifyData,
  VerifyDataError,
  VerifyDataSuccess,
} from "./auth.actions";
import {User, SocialUser} from "@kwot/data-models";
import * as SecureLS from "secure-ls";

export interface AuthState {
  user: User | any | null;
  registerUser: User | null;
  signInUserSocial: SocialUser | null;
  success: string;
  error: string;
}

const ls = new SecureLS({encodingType: 'aes'});
let data = ls.get('[KWOT]-kwotUser');

const initialAuthState: AuthState = {
  user: data ? JSON.parse(data) : null,
  registerUser: null,
  signInUserSocial: null,
  error: '', success: ''
}

const authReducer = createReducer(initialAuthState,
  on(Login, (state) => ({
    ...state,
    user: null,
    registerUser: null,
    signInUserSocial: null,
    error: '',
    success: ''
  })),
  on(LoginSuccess, (state, {loginUser, message}) => ({
    ...state,
    user: loginUser,
    registerUser: null,
    signInUserSocial: null,
    error: '',
    success: message
  })),
  on(LoginError, (state, {error}) => ({
    ...state,
    user: null,
    registerUser: null,
    signInUserSocial: null,
    error,
    success: ''
  })),
  on(Register, (state) => ({
    ...state,
    user: null,
    registerUser: null,
    signInUserSocial: null,
    error: '',
    success: ''
  })),
  on(SignInWithSocial, (state) => ({
    ...state,
    user: null,
    registerUser: null,
    signInUserSocial: null,
    error: '',
    success: ''
  })),

  on(RegisterSuccess, (state, {registerUser, message}) => ({
    ...state,
    user: null,
    registerUser,
    signInUserSocial: null,
    success: message,
    error: ''
  })),
  on(SignInWithSocialSuccess, (state, {signInUserSocial}) => ({
    ...state,
    user: null,
    registerUser: null,
    signInUserSocial,
    success: '',
    error: ''
  })),
  on(RegisterError, (state, {error}) => ({
    ...state,
    error,
    user: null,
    registerUser: null,
    signInUserSocial: null,
    success: ''
  })),
  on(ForgotPassword, (state) => ({
    ...state,
    user: null,
    registerUser: null,
    signInUserSocial: null,
    success: '',
    error: ''
  })),
  on(ForgotPasswordSuccess, (state) => ({
    ...state,
    user: null,
    registerUser: null,
    signInUserSocial: null,
    success: '---IGNORE---',
    error: ''
  })),
  on(ForgotPasswordError, (state, {error}) => ({
    ...state,
    user: null,
    registerUser: null,
    signInUserSocial: null,
    success: '',
    error
  })),
  on(ValidateOtp, (state, {ignoreClear}) => ({
    ...state,
    ...(!ignoreClear ? {user: null} : {}),
    registerUser: null,
    signInUserSocial: null,
    success: '',
    error: ''
  })),
  on(ValidateOtpSuccess, (state, {user, ignoreClear}) => ({
    ...state,
    ...(!ignoreClear ? {user} : {}),
    registerUser: null,
    signInUserSocial: null,
    success: '---IGNORE---',
    error: ''
  })),
  on(ValidateOtpError, (state, {ignoreClear}) => ({
    ...state,
    ...(!ignoreClear ? {user: null} : {}),
    registerUser: null,
    signInUserSocial: null,
    success: '',
    error: '---IGNORE---',
  })),
  on(SendOtp, (state) => ({
    ...state,
    user: null,
    registerUser: null,
    signInUserSocial: null,
    success: '',
    error: ''
  })),
  on(SendOtpSuccess, (state, {success}) => ({
    ...state,
    user: null,
    registerUser: null,
    signInUserSocial: null,
    success,
    error: ''
  })),
  on(SendOtpError, (state, {error}) => ({
    ...state,
    user: null,
    registerUser: null,
    signInUserSocial: null,
    success: '',
    error
  })),
  on(ResetPassword, (state) => ({
    ...state,
    user: null,
    registerUser: null,
    signInUserSocial: null,
    success: '',
    error: ''
  })),
  on(ResetPasswordSuccess, (state) => ({
    ...state,
    user: null,
    registerUser: null,
    signInUserSocial: null,
    success: '---IGNORE---',
    error: ''
  })),
  on(ResetPasswordError, (state, {error}) => ({
    ...state,
    user: null,
    registerUser: null,
    signInUserSocial: null,
    success: '',
    error
  })),
  on(CheckResetPasswordLink, (state) => ({
    ...state,
    success: '',
    error: ''
  })),
  on(CheckResetPasswordLinkSuccess, (state) => ({
    ...state,
    success: '',
    error: ''
  })),
  on(CheckResetPasswordLinkError, (state) => ({
    ...state,
    success: '',
    error: '---IGNORE---'
  })),
  on(CheckAccountVerifyLink, (state) => ({
    ...state,
    success: '',
    error: ''
  })),
  on(CheckAccountVerifyLinkSuccess, (state, {user}) => ({
    ...state,
    user,
    success: '---IGNORE---',
    error: ''
  })),

  on(VerifyData, (state) => ({...state, error: '', success: ''})),
  on(VerifyDataSuccess, (state, {success}) => ({...state, error: '', success})),
  on(VerifyDataError, (state, {error}) => ({...state, error, success: ''})),

  on(CheckAccountVerifyLinkError, (state) => ({
    ...state,
    success: '',
    error: '---IGNORE---'
  })),

  on(LogoutUser, (state) => ({
    ...state,
    user: null,
    registerUser: null,
    signInUserSocial: null,
    success: '',
    error: '',
  })),

  on(ResetAuthState, (state, {params}) => ({
    ...state,
    ...params
  }))
);

export function reducer(state = initialAuthState, action: Action) {
  return authReducer(state, action);
}
