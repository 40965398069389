import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {PhoneNumberFormat, CountryISO} from "ngx-intl-tel-input";
import {UntypedFormGroup} from "@angular/forms";
import {APP_CONFIG} from "@kwot/app-config";

@Component({
  selector: 'kwot-email-phone',
  templateUrl: './email-phone.component.html',
  styleUrls: ['./email-phone.component.scss']
})
export class EmailPhoneComponent implements OnInit {

  @Input() isRegisterWithPhoneNumber = false;
  @Input() isRegisterWithSocialAccount = false;
  PhoneNumberFormat = PhoneNumberFormat;
  CountryISO = CountryISO;
  @Input() formGroup: UntypedFormGroup;
  @Input() type: string = 'registration';

  @Output() onTypeChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    @Inject(APP_CONFIG) public appConfig: any
  ) {
  }

  ngOnInit(): void {
  }

  changeLoginType(isWithPhone: boolean) {
    this.isRegisterWithPhoneNumber = isWithPhone;
    this.onTypeChange.emit(isWithPhone);
  }

  get form() {
    return this.formGroup.controls;
  }

}
