<div class="modal-body">
  <div class="d-flex align-items-center justify-content-end mb-3">
    <span id="close" *ngIf="showCrossIcon">
    <img class="close-icon cursor-pointer" src="assets/images/icons/Cross.svg" alt="" (click)="bsModalRef.hide()">
  </span>
  </div>
  <div class="tabs">
    <ul class="nav nav-pills">
      <li class="cursor-pointer" [ngClass]="{'active': activeTab === 'terms'}" (click)="getData('terms')">
        <a data-toggle="tab">{{'terms_condition' | translate}}</a>
      </li>
      <li class="cursor-pointer" [ngClass]="{'active': activeTab === 'privacy'}" (click)="getData('privacy')">
        <a data-toggle="tab">{{'privacy_policy' | translate}}</a>
      </li>
    </ul>
  </div>
  <div class="tab-content">
    <div id="terms" class="tab-pane fade" [ngClass]="{'active in show': activeTab === 'terms'}"
         [innerHTML]="terms"></div>
    <div id="privacy" class="tab-pane fade" [ngClass]="{'active in show': activeTab === 'privacy'}"
         [innerHTML]="privacy"></div>
  </div>
</div>
