import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TreeviewModule} from "ngx-treeview";
import {CategoryTreeSelectComponent} from './components/category-tree-select/category-tree-select.component';
import {DisabledOnSelectorDirective} from "./components/category-tree-select/disabled-on-selector";
import {FormsModule} from "@angular/forms";
import {LazyLoadImageModule} from "ng-lazyload-image";
import {DataTablesModule} from "angular-datatables";
import {CustomTableComponent} from './components/custom-table/custom-table.component';
import {HeaderComponent} from "./components/header/header.component";
import {RouterModule} from "@angular/router";
import {SharedModule} from "@kwot/shared";
import {StoreModule} from "@ngrx/store";
import * as fromAdminVendor from "./+state/admin-vendor.reducer";
import {EffectsModule} from "@ngrx/effects";
import {AdminVendorEffects} from "./+state/admin-vendor.effects";

@NgModule({
  imports: [
    CommonModule,
    TreeviewModule.forRoot(),
    FormsModule,
    LazyLoadImageModule,
    DataTablesModule,
    RouterModule,
    SharedModule,
    StoreModule.forFeature('admin-vendor', fromAdminVendor.reducer),
    EffectsModule.forFeature([AdminVendorEffects]),
  ],
  declarations: [
    CategoryTreeSelectComponent,
    DisabledOnSelectorDirective,
    CustomTableComponent,
    HeaderComponent
  ],
  exports: [
    CategoryTreeSelectComponent,
    DataTablesModule,
    CustomTableComponent,
    HeaderComponent
  ]
})
export class AdminVendorSharedModule {
}
