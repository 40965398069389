import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {APP_CONFIG} from "@kwot/app-config";

@Injectable({
  providedIn: 'root'
})
export class VendorBuyerService {
  ApiUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private environment: any
  ) {
    this.ApiUrl = `${environment.apiUrl}`;
  }

  contactUs(params: any) {
    return this.http.post(`${this.ApiUrl}/${this.environment.type}/addContactUsDetails`, params);
  }

  getPolicyData(params: any) {
    return this.http.get(`${this.ApiUrl}/public/getPolicyData`, {params});
  }
}
