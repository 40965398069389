<ng-template #helpDesk>
  <div class="help me-3 d-md-block d-none">
    <a class="mb-0 fw-medium need-help cursor-pointer">{{'need_help' | translate}}</a>
  </div>
</ng-template>
<kwot-header [currentUser]="currentUser" [helpDesk]="helpDesk"></kwot-header>
<div class="side-menu">
  <div class="main-menu">
    <ul class="p-0 m-0">
      <li [ngClass]="{'active': (!currentRoute || currentRoute === 'dashboard')}">
        <a [routerLink]="['/admin', 'dashboard']" class="fs-16">
          <img class="dark-icon me-3" src="/assets/images/icons/dashboard-dark-icon.svg" alt="">
          <img class="light-icon me-3" src="/assets/images/icons/dashboard-light-icon.svg" alt="">
          <span>{{'dashboard' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'sellers'}">
        <a [routerLink]="['/admin', 'sellers']" class="fs-16">
          <img class="dark-icon me-3" src="/assets/images/icons/sales-dark-icon.svg" alt="">
          <img class="light-icon me-3" src="/assets/images/icons/sales-light-icon.svg" alt="">
          <span>{{'sellers' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'buyers'}">
        <a [routerLink]="['/admin', 'buyers']" class="fs-16">
          <img class="dark-icon me-3" src="/assets/images/icons/total-buyers-dark.svg" alt="">
          <img class="light-icon me-3" src="/assets/images/icons/total-buyers-light.svg" alt="">
          <span>{{'buyers' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'orders'}">
        <a [routerLink]="['/admin', 'orders']" class="fs-16">
          <img class="dark-icon me-3" src="/assets/images/icons/order-dark-icon.svg" alt="">
          <img class="light-icon me-3" src="/assets/images/icons/order-light-icon.svg" alt="">
          <span>{{'orders' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'products'}">
        <a [routerLink]="['/admin', 'products']" class="fs-16">
          <img class="dark-icon me-md-3" src="/assets/images/icons/products-icon-dark.svg" alt="">
          <img class="light-icon me-md-3" src="/assets/images/icons/products-icon-light.svg" alt="">
          <span>{{'products_no_bracket' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'inbox'}">
        <a [routerLink]="['/admin', 'inbox']" class="fs-16">
          <img class="dark-icon me-3" src="/assets/images/icons/inbox-dark-icon.svg" alt="">
          <img class="light-icon me-3" src="/assets/images/icons/inbox-light-icon.svg" alt="">
          <span>{{'inbox' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'audit-logs'}">
        <a [routerLink]="['/admin', 'audit-logs']" class="fs-16">
          <img class="dark-icon me-3" height="30px" width="30px" src="/assets/images/icons/privacy-dark.png" alt="">
          <img class="light-icon me-3" height="30px" width="30px" src="/assets/images/icons/privacy-light.png" alt="">
          <span>{{'audit_logs' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'revisions'}">
        <a [routerLink]="['/admin', 'revisions']" class="fs-16">
          <img class="dark-icon me-3" height="30px" width="30px" src="/assets/images/icons/privacy-dark.png" alt="">
          <img class="light-icon me-3" height="30px" width="30px" src="/assets/images/icons/privacy-light.png" alt="">
          <span>{{'revisions' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': (currentRoute === 'reviews')}">
        <a [routerLink]="['/admin', 'reviews']" class="fs-16">
          <img class="dark-icon me-md-3" src="/assets/images/icons/review-dark.svg" alt="">
          <img class="light-icon me-md-3" src="/assets/images/icons/review-light.svg" alt="">
          <span> {{'reviews' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'finance'}">
        <a [routerLink]="['/admin', 'finance']" class="fs-16">
          <img class="dark-icon me-3" src="/assets/images/icons/financial-dark-icon.svg" alt="">
          <img class="light-icon me-3" src="/assets/images/icons/financial-light-icon.svg" alt="">
          <span>{{'finances' | translate}}</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/admin', 'dashboard']" disabled class="fs-16" containerClass="custom-tooltip"
           tooltip="{{'coming_soon' | translate}}" placement="right">
          <img class="dark-icon me-3" src="/assets/images/icons/staff-dark-icon.svg" alt="">
          <img class="light-icon me-3" src="/assets/images/icons/staff-light-icon.svg" alt="">
          <span>{{'staff' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'categories'}">
        <a [routerLink]="['/admin', 'categories']" class="fs-16">
          <i class="dark-icon me-3 fa fa-list fs-22"></i>
          <i class="light-icon me-3 fa fa-list fs-22"></i>
          <span>{{'categories' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'site-settings'}">
        <a [routerLink]="['/admin', 'site-settings']" class="fs-16">
          <img class="dark-icon me-3" src="/assets/images/icons/settings-icon-dark.svg" alt="">
          <img class="light-icon me-3" src="/assets/images/icons/settings-icon-light.svg" alt="">
          <span>{{'site_settings' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'currency-rate'}">
        <a [routerLink]="['/admin', 'currency-rate']" class="fs-16">
          <img class="dark-icon me-3" height="30px" width="30px" src="/assets/images/icons/privacy-dark.png" alt="">
          <img class="light-icon me-3" height="30px" width="30px" src="/assets/images/icons/privacy-light.png" alt="">
          <span>{{'currency rate' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'shipping-cost'}">
        <a [routerLink]="['/admin', 'shipping-cost']" class="fs-16">
          <img class="dark-icon me-3" height="30px" width="30px" src="/assets/images/icons/privacy-dark.png" alt="">
          <img class="light-icon me-3" height="30px" width="30px" src="/assets/images/icons/privacy-light.png" alt="">
          <span>{{'shipping cost' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentRoute === 'policy'}">
        <a [routerLink]="['/admin', 'policy']" class="fs-16">
          <img class="dark-icon me-3" height="30px" width="30px" src="/assets/images/icons/privacy-dark.png" alt="">
          <img class="light-icon me-3" height="30px" width="30px" src="/assets/images/icons/privacy-light.png" alt="">
          <span>{{'policy' | translate}}</span>
        </a>
      </li>
    </ul>
  </div>
</div>
