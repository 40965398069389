import {createAction, props} from "@ngrx/store";
import {CurrencyData} from "@kwot/data-models";

const SharedActions = {
  SAVE_MEASUREMENTS: '[SHARED] Save Measurements',
  SAVE_SAVE_MEASUREMENTS_SUCCESS: '[SHARED] Save Measurements Success',
  SAVE_SAVE_MEASUREMENTS_ERROR: '[SHARED] Save Measurements Error',
  GET_CURRENCY_DATA: '[SHARED] Get Currency Data',
  GET_CURRENCY_DATA_SUCCESS: '[SHARED] Get Currency Data Success',
  GET_CURRENCY_DATA_ERROR: '[SHARED] Get Currency Data Error',
  RESET_SHARED_STATE: '[SHARED] Reset Shared State'
}

export const SaveMeasurements = createAction(SharedActions.SAVE_MEASUREMENTS, props<{ body: any, allowGuest?: any }>());
export const SaveMeasurementsSuccess = createAction(SharedActions.SAVE_SAVE_MEASUREMENTS_SUCCESS, props<{ measurements: any }>());
export const SaveMeasurementsError = createAction(SharedActions.SAVE_SAVE_MEASUREMENTS_ERROR, props<{ error: string }>());

export const GetCurrencyData = createAction(SharedActions.GET_CURRENCY_DATA);
export const GetCurrencyDataSuccess = createAction(SharedActions.GET_CURRENCY_DATA_SUCCESS, props<{ currencyData: CurrencyData }>());
export const GetCurrencyDataError = createAction(SharedActions.GET_CURRENCY_DATA_ERROR, props<{ error: string }>());

export const ResetSharedState = createAction(SharedActions.RESET_SHARED_STATE, (params: any = {}) => params);
