import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthState, CheckAccountVerifyLink, getAuthError, getAuthSuccess} from "@kwot/auth";
import {select, Store} from "@ngrx/store";
import {Subject, takeUntil} from "rxjs";
import {APP_CONFIG, LocalstorageService} from "@kwot/app-config";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'kwot-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit, OnDestroy {

  responseReady = false;
  error = false;
  success = false;
  unsubscriber = new Subject();

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private authStore: Store<AuthState>,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private localStorageService: LocalstorageService,
    @Inject(APP_CONFIG) public appConfig: any
  ) {
    this.activeRoute.queryParams.subscribe(query => {
      if (query && query.token) {
        this.authStore.dispatch(CheckAccountVerifyLink({id: query.token}));
      } else {
        this.router.navigate(['/', this.appConfig.type, 'login']);
      }
    })
  }

  ngOnInit(): void {
    this.authStore.pipe(select(getAuthSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          this.responseReady = true;
          this.success = true;
          this.error = false;
          const user = this.localStorageService.updateUserKey('get');
          if (user) {
            user.isEmailVerified = true;
            this.localStorageService.updateUserKey('store', user);
          }
          this.toastr.success(this.translateService.instant('email_verified_success'))
          if (user.isDeleted) {
            this.router.navigate(['/', this.appConfig.type, 'reset-password']);
            return;
          }
          if (this.appConfig.type === "user") {
            this.router.navigate(['user', 'home']);
            return;
          }
          this.router.navigate(['/', this.appConfig.type]);
        }
      })
    this.authStore.pipe(select(getAuthError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.responseReady = true;
          this.error = true;
          this.success = false;
          this.toastr.error(this.translateService.instant('email_verified_expired'));
          const user = this.localStorageService.updateUserKey('get');
          if (user) {
            this.router.navigate(['/', this.appConfig.type, 'email-verification'], {queryParams: {type: 'expired'}});
          } else {
            this.router.navigate(['/', this.appConfig.type, 'login']);
          }
        }
      })
  }


  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
  }

}
