import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {map} from 'rxjs';
import {AuthState, getLoggedInUser} from "@kwot/auth";
import {select, Store} from "@ngrx/store";

@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authStore: Store<AuthState>
  ) {
  }

  canActivate() {
    return this.authStore.pipe(select(getLoggedInUser)).pipe(
      map(currentUser => {
        if (currentUser) {
          this.router.navigate(['/admin', 'dashboard']);
          return false;
        }
        return true;
      })
    )
  }

}
