import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {User} from "@kwot/data-models";
import {AuthState, ForgotPassword, getAuthSuccess, ResetAuthState} from "@kwot/auth";
import {select, Store} from "@ngrx/store";
import {Subject, takeUntil} from "rxjs";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {APP_CONFIG, LocalstorageService} from "@kwot/app-config";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'kwot-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  forgotPasswordForm: UntypedFormGroup;
  unsubscriber = new Subject();
  isRegisterWithPhoneNumber = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authStore: Store<AuthState>,
    private toastr: ToastrService,
    private router: Router,
    private translateService: TranslateService,
    @Inject(APP_CONFIG) public appConfig: any,
    private localStorageService: LocalstorageService
  ) {
    this.authStore.dispatch(ResetAuthState({params: {error: '', success: ''}}));
    this.subscribeToStore();
  }

  subscribeToStore() {
    this.authStore.pipe(select(getAuthSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          if (this.isRegisterWithPhoneNumber) {
            this.localStorageService.updateRegisterNumber('store', this.forgotPasswordForm.value.phone?.number || '');
            this.localStorageService.updateCountryCode('store', this.forgotPasswordForm.value.phone?.dialCode.replace('+', '') || '');
            this.localStorageService.updateRedirectUrl('store', 'reset-password');
            this.toastr.warning(this.translateService.instant('forgot_otp_sent'), this.translateService.instant('sms_sent'));
            this.router.navigate(['/', this.appConfig.type, 'validate-otp'])
          } else {
            this.toastr.warning(this.translateService.instant('forgot_email_sent'), this.translateService.instant('email_sent'));
            this.router.navigate(['/', this.appConfig.type, 'login']);
          }
        }
      })
  }

  ngOnInit(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', Validators.required],
      phone: ['']
    })
  }

  get form() {
    return this.forgotPasswordForm.controls;
  }

  changeLoginType(isWithPhone: boolean) {
    this.isRegisterWithPhoneNumber = isWithPhone;
    if (isWithPhone) {
      this.forgotPasswordForm.patchValue({email: '', phone: ''});
      this.forgotPasswordForm.get('email')?.clearValidators();
      this.forgotPasswordForm.get('phone')?.setValidators(Validators.required);
    } else {
      this.forgotPasswordForm.patchValue({email: '', phone: ''});
      this.forgotPasswordForm.get('phone')?.clearValidators();
      this.forgotPasswordForm.get('email')?.setValidators([Validators.required, Validators.pattern(RegExp(/^[a-zA-z0-9._%+-]+@[a-zA-z0-9.-]+\.[a-zA-z]{2,4}$/))]);
    }
    this.forgotPasswordForm.get('email')?.updateValueAndValidity();
    this.forgotPasswordForm.get('phone')?.updateValueAndValidity();
  }

  submitForm() {
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    const body: User | any = {
      ...(this.isRegisterWithPhoneNumber ? {
        phone: this.forgotPasswordForm.value.phone?.number || '',
        countryCode: this.forgotPasswordForm.value.phone?.dialCode.replace('+', '') || ''
      } : {
        email: this.forgotPasswordForm.value.email,
        redirect_url: `${window.location.origin}/${this.appConfig.type}/reset-password`
      })
    }

    this.authStore.dispatch(ForgotPassword({user: body}));
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
  }

}
