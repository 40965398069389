import {createFeatureSelector, createSelector} from "@ngrx/store";
import {AdminState} from "./admin.reducer";

const getAdminState = createFeatureSelector<AdminState>('admin');

export const getAdminDashboard = createSelector(getAdminState, (state: AdminState) => state.dashboard);

export const getAllOrders = createSelector(getAdminState, (state: AdminState) => state.orders);

export const getAdminError = createSelector(getAdminState, (state: AdminState) => state.error);

export const getAdminSuccess = createSelector(getAdminState, (state: AdminState) => state.success);

export const getPayout = createSelector(getAdminState, (state: AdminState) => state.payout);

export const getReviews = createSelector(getAdminState, (state: AdminState) => state.reviews);
