<div class="auth-component">
  <img class="bg-right--img d-none d-md-block" src="assets/images/bg/auth-Bg.png" alt="">
  <img class="bg-right--img d-md-none" src="assets/images/bg/auth-mobile-Bg.png" alt="">
  <div class="container">
    <div class="row">
      <div class="col-xl-4 col-md-5 pt-56 pt-md-36">
        <div class="action--form">
          <div class="logo pb-50 text-center">
            <img class="w-50" src="assets/images/Logo.svg"
                 alt="">
          </div>

          <form class="login-form" (ngSubmit)="!loginForm.invalid && submitForm()" novalidate [formGroup]="loginForm">
            <h1 class="register-title text-center">{{ 'sign_in' | translate }}</h1>
            <kwot-email-phone [formGroup]="loginForm" type="sign_in"
                              (onTypeChange)="changeLoginType($event)"></kwot-email-phone>
            <div class="form-group">
              <div class="position-relative">
                <div class="d-flex justify-content-between">
                  <label class="mb-0 ms-2 fs-12 fw-bold">{{'password' | translate}}</label>
                  <ng-container *ngIf="(form.password.dirty || form.password.touched) && form.password.errors">
                    <label *ngIf="form.password.errors.required"
                           class="text-danger mb-0 me-2 text-end fs-10 fw-bold">
                      {{ 'errors.is_required' | translate: {field: 'password' | translate} }}</label>
                  </ng-container>
                </div>
                <div class="position-relative">
                  <input [type]="showPassword ? 'text' : 'password'" formControlName="password"
                         class="form-control br-16"
                         placeholder="{{'enter_your_field' | translate: {field: 'password' | translate} }}">
                  <span class="cursor-pointer eye-cond">
                      <img [ngClass]="{'d-none': showPassword}" (click)="showPassword = true"
                           src="assets/images/icons/open-eye-icon.svg" alt="">
                      <img [ngClass]="{'d-none': !showPassword}" (click)="showPassword = false"
                           src="assets/images/icons/close-eye-icon.svg" alt="">
                  </span>
                </div>
              </div>
            </div>
            <div class="text-start pt-2 d-flex align-items-center">
              <div class="fs-16 fw-bold terms-of-use text-center cursor-pointer"><a
                [routerLink]="['/', appConfig.type, 'forgot-password']">{{'forgot_my_password' | translate}}</a></div>
            </div>
            <button type="submit" class="my-4 btn-action w-100 p-14"
                    [ngClass]="{'btn-disabled': loginForm.invalid, 'btn-action': loginForm.valid}">{{'sign_in' | translate}}
            </button>
          </form>
          <ng-container *ngIf="appConfig.type === 'vendor' || appConfig.type === 'user'">
            <div class="col-md-12 fs-14 fw-bold terms-of-use text-center">
              {{'continue_with' | translate}}
            </div>
            <div class="col-md-12 text-center mt-2">
              <img src="assets/images/icons/google-icon.svg" alt="" class="cursor-pointer" (click)="loginWithGoogle()">
            </div>
          </ng-container>
          <div class="mt-3">
            <div class="sign-in text-center">
              <p class="sign-in-link fw-bold">{{'dont_have_account' | translate}} <a
                [routerLink]="['/', appConfig.type, 'register']">{{'register' | translate}}</a></p>
            </div>
          </div>
          <ng-container *ngIf="appConfig.type === 'user'">
            <div class="mt-3 d-flex align-items-center justify-content-center">
              <a [href]="appConfig.becomeAVendorUrl" target="_blank"
                 class="my-4 w-100 p-14 btn-green-border text-center">{{'register_as_seller' | translate}}
              </a>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
