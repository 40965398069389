import {createAction, props} from "@ngrx/store";
import {
  AdminDashboard,
  PaginatedOrders,
  PayoutDetails
} from "@kwot/data-models";

const AdminActions = {
  DASHBOARD: '[ADMIN] Dashboard',
  DASHBOARD_SUCCESS: '[ADMIN] Dashboard Success',
  DASHBOARD_ERROR: '[ADMIN] Dashboard Error',
  ORDERS_LIST: '[ADMIN] Orders List',
  ORDERS_LIST_SUCCESS: '[ADMIN] Orders List Success',
  ORDERS_LIST_ERROR: '[ADMIN] Orders List Error',
  GET_CSV_FILE: '[ADMIN] Get Csv File',
  GET_CSV_FILE_SUCCESS: '[ADMIN] Get Csv File Success',
  GET_CSV_FILE_ERROR: '[ADMIN] Get Csv File Error',
  GET_PAYOUT_LIST: "[ADMIN] Get Payout List",
  GET_PAYOUT_LIST_SUCCESS: "[ADMIN] Get Payout List Success",
  GET_PAYOUT_LIST_ERROR: "[ADMIN] Get Payout List Error",
  UPDATE_PAYOUT_STATUS: "[ADMIN] Update Payout Status",
  UPDATE_PAYOUT_STATUS_SUCCESS: "[ADMIN] Update Payout Status Success",
  UPDATE_PAYOUT_STATUS_ERROR: "[ADMIN] Update Payout Status Error",
  GET_REVIEWS: "[ADMIN] Get Reviews",
  GET_REVIEWS_SUCCESS: "[ADMIN] Get Reviews Success",
  GET_REVIEWS_ERROR: "[ADMIN] Get Reviews Error",
  DELETE_REVIEW: "[ADMIN] Delete Reviews",
  DELETE_REVIEW_SUCCESS: "[ADMIN] Delete Reviews Success",
  DELETE_REVIEW_ERROR: "[ADMIN] Delete Reviews Error",
  RESET_ADMIN_STATE: '[ADMIN] Reset Admin State'
}

export const Dashboard = createAction(AdminActions.DASHBOARD, (params: any = {}) => params);
export const DashboardSuccess = createAction(AdminActions.DASHBOARD_SUCCESS, props<{ dashboard: AdminDashboard }>());
export const DashboardError = createAction(AdminActions.DASHBOARD_ERROR, props<{ error: string }>());

export const OrdersList = createAction(AdminActions.ORDERS_LIST, (params: any = {}) => params);
export const OrdersListSuccess = createAction(AdminActions.ORDERS_LIST_SUCCESS, props<{ orders: PaginatedOrders }>());
export const OrdersListError = createAction(AdminActions.ORDERS_LIST_ERROR, props<{ error: string }>());

export const GetCsvFile = createAction(AdminActions.GET_CSV_FILE, (params: any = {}) => params);
export const GetCsvFileSuccess = createAction(AdminActions.GET_CSV_FILE_SUCCESS, props<{ csvFile: any }>());
export const GetCsvFileError = createAction(AdminActions.GET_CSV_FILE_ERROR, props<{ error: string }>());

export const GetPayoutList = createAction(AdminActions.GET_PAYOUT_LIST, (params: any = {}) => params);
export const GetPayoutListSuccess = createAction(AdminActions.GET_PAYOUT_LIST_SUCCESS, props<{ payout: PayoutDetails }>());
export const GetPayoutListError = createAction(AdminActions.GET_PAYOUT_LIST_ERROR, props<{ error: string }>());

export const UpdatePayoutStatus = createAction(AdminActions.UPDATE_PAYOUT_STATUS, (params: any = {}) => params);
export const UpdatePayoutStatusSuccess = createAction(AdminActions.UPDATE_PAYOUT_STATUS_SUCCESS, props<{ payout: PayoutDetails }>());
export const UpdatePayoutStatusError = createAction(AdminActions.UPDATE_PAYOUT_STATUS_ERROR, props<{ error: string }>());

export const GetReviews = createAction(AdminActions.GET_REVIEWS, (params: any = {}) => params);
export const GetReviewsSuccess = createAction(AdminActions.GET_REVIEWS_SUCCESS, props<{ reviews: any }>());
export const GetReviewsError = createAction(AdminActions.GET_REVIEWS_ERROR, props<{ error: string }>());

export const DeleteReviews = createAction(AdminActions.DELETE_REVIEW, (params: any = {}) => params);
export const DeleteReviewsSuccess = createAction(AdminActions.DELETE_REVIEW_SUCCESS, props<{ id: any }>());
export const DeleteReviewsError = createAction(AdminActions.DELETE_REVIEW_ERROR, props<{ error: string }>());

export const ResetAdminState = createAction(AdminActions.RESET_ADMIN_STATE, (params: any = {}) => params)
