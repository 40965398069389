export enum OrderStatus {
  PENDING,
  ACCEPTED,
  REJECTED,
  ONTHEWAY,
  COMPLETED,
  CANCELD,
  RETURN_INITIALIZED,
  RETURN_IN_TRANSIT,
  RETURN_COMPLETED
}

export enum ReturnOrderStatus {
  PENDING,
  ACCEPTED,
  COMPLETED,
  CANCELD,
}

export const OrderStatusText = {
  [OrderStatus.PENDING]: 'Pending',
  [OrderStatus.ACCEPTED]: 'Accepted',
  [OrderStatus.REJECTED]: 'Canceled',
  [OrderStatus.ONTHEWAY]: 'Shipped',
  [OrderStatus.COMPLETED]: 'Delivered',
  [OrderStatus.CANCELD]: 'Canceled',
  [OrderStatus.RETURN_INITIALIZED]: 'Return request',
  [OrderStatus.RETURN_IN_TRANSIT]: 'Return In Progress',
  [OrderStatus.RETURN_COMPLETED]: 'Return Complete',
}

export const OrderStatusForFilterText = {
  [OrderStatus.PENDING]: 'Pending',
  [OrderStatus.ACCEPTED]: 'Accepted',
  [OrderStatus.REJECTED]: 'Canceled',
  [OrderStatus.ONTHEWAY]: 'Shipped',
  [OrderStatus.COMPLETED]: 'Delivered',
  [OrderStatus.CANCELD]: 'Canceled',
}

export const ReturnOrderStatusText = {
  [ReturnOrderStatus.PENDING]: 'Return request',
  [ReturnOrderStatus.ACCEPTED]: 'Return In Progress',
  [ReturnOrderStatus.COMPLETED]: 'Return Complete',
  [ReturnOrderStatus.CANCELD]: 'Return Rejected'
}
