<div class="verification">
  <ng-container *ngIf="responseReady">
    <div class="success" *ngIf="success">
      <i class="far fa-check-circle text-success"></i>
      <h1>{{'success' | translate}}</h1>
      {{'email_is_verified' | translate | uppercase}}
    </div>
    <div class="error" *ngIf="error">
      <i class="far fa-times-circle text-error"></i>
      <h1>{{'error' | translate}}</h1>
      {{'email_not_verified' | translate}}
    </div>
  </ng-container>
</div>
