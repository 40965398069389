import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {APP_CONFIG} from "@kwot/app-config";

@Injectable({
  providedIn: 'root'
})
export class AdminVendorService {
  ApiUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private environment: any
  ) {
    this.ApiUrl = `${environment.apiUrl}/${environment.type}`;
  }

  getNotifications(params: any) {
    return this.http.get(`${this.ApiUrl}/getAllNotification`, {params});
  }

  readNotification(id: string) {
    return this.http.get(`${this.ApiUrl}/readNotification`, {params: {id}});
  }

  updateNotification(params: any) {
    return this.http.put(`${this.ApiUrl}/updateNotification`, params);
  }
}
