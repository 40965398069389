import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {APP_CONFIG} from "@kwot/app-config";
import {User} from "@kwot/data-models";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  ApiUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: any
  ) {
    this.ApiUrl = `${this.appConfig.apiUrl}/${this.appConfig.type}`;
  }


  register(user: User) {
    let Api = '';
    if (user.provider) {
      Api = 'socialLogin'
    } else {
      Api = 'register';
    }
    return this.http.post(`${this.ApiUrl}/${Api}`, user, {
      headers: new HttpHeaders().set('Skip-auth', 'true')
    });
  }

  login(user: User) {
    let Api = '';
    if (user.provider) {
      Api = 'socialLogin'
    } else {
      Api = 'login';
    }
    return this.http.post(`${this.ApiUrl}/${Api}`, user, {
      headers: new HttpHeaders().set('Skip-auth', 'true')
    });
  }

  forgotPassword(user: User) {
    return this.http.post(`${this.ApiUrl}/forgotPassword`, user, {
      headers: new HttpHeaders().set('Skip-auth', 'true')
    });
  }

  validateOtp(otp: string, type: string) {
    let url = '';
    let headers = new HttpHeaders().set('Skip-auth', 'true');
    if (type === 'vendor' || type === 'user') {
      url = 'verifyProfileOtp';
      headers = headers.append('AddTempAuth', 'true');
    } else {
      url = 'verifyOtp';
    }
    return this.http.post(`${this.ApiUrl}/${url}`, otp, {
      headers: headers
    });
  }

  sendOtp(phone: string, countryCode: string) {
    return this.http.post(`${this.ApiUrl}/resendOtp`, {phone, countryCode}, {
      headers: new HttpHeaders().set('Skip-auth', 'true')
    });
  }

  resetPassword(passwordBody: any) {
    let url = '';
    let headers = new HttpHeaders().set('Skip-auth', 'true');
    if (passwordBody.link) {
      url = 'resetPasswordByResetLink';
    } else {
      url = 'changePassword';
      headers = headers.append('AddTempAuth', 'true');
    }
    return this.http.put(`${this.ApiUrl}/${url}`, {...passwordBody}, {headers});
  }

  checkResetPasswordLink(token: string) {
    return this.http.get(`${this.ApiUrl}/checkPasswordResetLink/${token}`, {
      headers: new HttpHeaders().set('Skip-auth', 'true')
    });
  }

  checkAccountVerifyLink(token: string) {
    return this.http.get(`${this.ApiUrl}/checkVerificationLink/${token}`, {
      headers: new HttpHeaders().set('Skip-auth', 'true')
    });
  }

  verifyData(params: any) {
    return this.http.post(`${this.ApiUrl}/sendProfileVerification`, params);
  }
}
