import {createAction, props} from "@ngrx/store";
import {Policy} from "@kwot/data-models";

const VendorBuyerActions = {
  CONTACT_US_DATA: '[VENDOR_BUYER_SHARED] Add contact us data',
  CONTACT_US_DATA_SUCCESS: '[VENDOR_BUYER_SHARED] Add contact us data success',
  CONTACT_US_DATA_ERROR: '[VENDOR_BUYER_SHARED] Add contact us data error',
  GET_POLICY_DATA: '[VENDOR_BUYER_SHARED] Get policy data',
  GET_POLICY_DATA_SUCCESS: '[VENDOR_BUYER_SHARED] Get policy data success',
  GET_POLICY_DATA_ERROR: '[VENDOR_BUYER_SHARED] Get policy data error',
  RESET_VENDOR_BUYER_STATE: '[VENDOR_BUYER_SHARED] Reset Vendor Buyer State'
}

export const addContactData = createAction(VendorBuyerActions.CONTACT_US_DATA, props<{ body: any }>());
export const addContactDataSuccess = createAction(VendorBuyerActions.CONTACT_US_DATA_SUCCESS);
export const addContactDataError = createAction(VendorBuyerActions.CONTACT_US_DATA_ERROR, props<{ error: string }>());

export const GetPolicyData = createAction(VendorBuyerActions.GET_POLICY_DATA, (params: any = {}) => params);
export const GetPolicyDataSuccess = createAction(VendorBuyerActions.GET_POLICY_DATA_SUCCESS, props<{ policy: Policy }>());
export const GetPolicyDataError = createAction(VendorBuyerActions.GET_POLICY_DATA_ERROR, props<{ error: string }>());

export const ResetVendorBuyerState = createAction(VendorBuyerActions.RESET_VENDOR_BUYER_STATE, (params: any = {}) => params);
