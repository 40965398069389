import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {
  AuthState, getLoggedInUser,
  getRegisterUser,
  getSignInWithSocialUser,
  Register,
  ResetAuthState,
  SignInWithSocial
} from "@kwot/auth";
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators} from "@angular/forms";
import {Subject, takeUntil} from "rxjs";
import {GoogleLoginProvider, SocialAuthService} from "angularx-social-login";
import {SocialUser, User} from "@kwot/data-models";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {PrivacyTermsComponent} from "../../../../../vendor-buyer-shared/src/lib/components/privacy-terms/privacy-terms.component";
import {APP_CONFIG, LocalstorageService} from "@kwot/app-config";
import {Router} from "@angular/router";

@Component({
  selector: 'kwot-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {

  registerForm: UntypedFormGroup;
  showPassword: boolean = false;
  unsubscriber = new Subject();
  buyer_email: any
  buyer_password: any
  RegExpValidator = {
    'lowerCase': RegExp(/^(?=.*?[a-z])/),
    'upperCase': RegExp(/^(?=.*?[A-Z])/),
    'digit': RegExp(/^(?=.*?[0-9])/),
    'specialChar': RegExp(/^(?=.*?[" !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"])/),
    'email': RegExp(/^[a-zA-z0-9._%+-]+@[a-zA-z0-9.-]+\.[a-zA-z]{2,4}$/)
  };

  roles: any[] = [
    {
      name: 'Staff',
      value: 'STAFF'
    },
    {
      name: 'Management',
      value: 'MANAGEMENT'
    },
    {
      name: 'IT',
      value: 'IT'
    }
  ];
  isRegisterWithPhoneNumber = false;
  isRegisterWithSocialAccount = false;
  socialProviderResponse: SocialUser;

  constructor(
    private authStore: Store<AuthState>,
    private formBuilder: UntypedFormBuilder,
    private modelService: BsModalService,
    private authSocialService: SocialAuthService,
    private router: Router,
    @Inject(APP_CONFIG) public appConfig: any,
    private localStorageService: LocalstorageService
  ) {
    this.authStore.dispatch(ResetAuthState({params: {error: '', success: '', registerUser: null}}));
    if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras?.state) {
      this.buyer_email = this.router.getCurrentNavigation().extras.state.email || ''
      this.buyer_password = this.router.getCurrentNavigation().extras.state.password || ''
    }

    this.subscribeToStore();
  }

  subscribeToStore() {
    this.authStore.pipe(select(getRegisterUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(registerUser => {
        if (registerUser) {
          if (this.isRegisterWithPhoneNumber && this.appConfig.type !== 'admin') {
            this.localStorageService.updateRegisterNumber('store', this.registerForm.value.phone?.number || '');
            this.localStorageService.updateCountryCode('store', this.registerForm.value.phone?.dialCode.replace('+', '') || '');
            this.localStorageService.updateRegisterUser('store', registerUser);
            this.router.navigate(['/', this.appConfig.type, 'validate-otp'])
          } else {
            if (this.isRegisterWithSocialAccount || this.appConfig.type === 'admin') {
              this.authStore.dispatch(ResetAuthState({params: {user: registerUser}}));
              this.localStorageService.clearAllLocalStorage();
              this.localStorageService.updateUserKey('store', registerUser);
              this.router.navigate(['/', this.appConfig.type]);
            } else {
              this.router.navigate(['/', this.appConfig.type, 'login']);
            }
          }
        }
      })
    this.authStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(currentUser => {
        if (currentUser) {
          this.localStorageService.updateUserKey('store', currentUser);
          // const route = this.localStorageService.updateRedirectUrl('get');
          if (this.appConfig.type === 'vendor' || this.appConfig.type === 'user') {
            if (currentUser.isDeleted) {
              this.localStorageService.updateCommonKey('store', {redirect: false});
              this.router.navigate([`/${this.appConfig.type}`, 'reactivate-account']);
              return;
            }
            if (currentUser.email && !currentUser.isEmailVerified) {
              this.localStorageService.updateCommonKey('store', {redirect: false});
              this.router.navigate([`/${this.appConfig.type}`, 'email-verification']);
              return;
            }
            if (currentUser.phone && !currentUser.isPhoneVerified && !currentUser.isEmailVerified) {
              this.localStorageService.updateCommonKey('store', {redirect: false});
              this.localStorageService.updateRegisterNumber('store', currentUser.phone || '');
              this.localStorageService.updateCountryCode('store', currentUser.phoneCountry || '');
              this.localStorageService.updateRegisterUser('store', currentUser);
              this.router.navigate(['/', this.appConfig.type, 'validate-otp'])
              return;
            }
          }
        }
      })

    this.authStore.pipe(select(getSignInWithSocialUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(registerUserSocial => {
        if (registerUserSocial) {
          this.socialProviderResponse = registerUserSocial;
          this.isRegisterWithSocialAccount = true;
          this.changeLoginType(false);
          this.registerForm.patchValue({
            password: '',
            firstName: registerUserSocial.firstName,
            lastName: registerUserSocial.lastName,
            email: registerUserSocial.email
          });
          this.registerForm.get('password')?.clearValidators();
          this.registerForm.get('password')?.updateValueAndValidity();
        }
      })
  }

  changeLoginType(isWithPhone: boolean) {
    this.isRegisterWithPhoneNumber = isWithPhone;
    if (this.isRegisterWithPhoneNumber) {
      this.registerForm.patchValue({email: '', phone: ''});
      this.registerForm.get('email')?.clearValidators();
      this.registerForm.get('phone')?.setValidators(Validators.required);
    } else {
      this.registerForm.patchValue({email: '', phone: ''});
      this.registerForm.get('phone')?.clearValidators();
      this.registerForm.get('email')?.setValidators([Validators.required, Validators.pattern(this.RegExpValidator.email)]);
    }
    this.registerForm.get('email')?.updateValueAndValidity();
    this.registerForm.get('phone')?.updateValueAndValidity();
  }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: [this.buyer_email || '', Validators.required],
      phone: [''],
      password: ['', Validators.compose([
        Validators.required, Validators.minLength(8), Validators.maxLength(30),
        this.validate('lowerCase', this.RegExpValidator.lowerCase), this.validate('upperCase', this.RegExpValidator.upperCase),
        this.validate('digit', this.RegExpValidator.digit), this.validate('specialChar', this.RegExpValidator.specialChar)
      ])],
      ...(this.appConfig.type === 'admin' ? {role: ['', Validators.required]} : {}),
      terms: ['', Validators.requiredTrue],
      ...(this.appConfig.type === 'vendor' ? {recaptchaChecked: [null, Validators.required]} : {})
    });
  }

  validate(criteria: string, regex: RegExp): ValidatorFn {
    return (control: AbstractControl): any => {
      if (!control || !control.value || control.value.length === 0) {
        return undefined;
      }
      if (!regex.test(control.value)) {
        const failed: any = {};
        failed[criteria] = {
          actualValue: control.value,
          requiredPattern: regex
        };
        return failed;
      }
      return undefined;
    };
  }

  get form() {
    return this.registerForm.controls;
  }

  submitForm() {
    if (this.registerForm.invalid) {
      return;
    }

    this.registerForm.value.email = this.registerForm.value.email.trim()

    const formValues = {...this.registerForm.value};
    const body: User = this.getBody(formValues);

    this.authStore.dispatch(Register({user: body}));
  }

  getBody(formValues: any) {
    if (this.appConfig.type === 'admin') {
      return {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        ...(this.isRegisterWithPhoneNumber ? {
          phone: (formValues.phone?.number || '').replace(/\s/g, ''),
          countryCode: formValues.phone?.dialCode.replace('+', '') || ''
        } : {
          email: formValues.email,
        }),
        password: formValues.password,
        role: formValues.role,
        // redirect_url: `${window.location.origin}/${this.appConfig.type}/verify-email`
      }
    } else if (this.appConfig.type === 'vendor' || this.appConfig.type === 'user') {
      return {
        ...(this.appConfig.type === 'user' ? {
          firstName: formValues.firstName,
          lastName: formValues.lastName,
        } : {name: `${formValues.firstName} ${formValues.lastName}`}),
        ...(this.isRegisterWithPhoneNumber ? {
          phone: (formValues.phone?.number || '').replace(/\s/g, ''),
          countryCode: formValues.phone?.dialCode.replace('+', '') || ''
        } : {
          email: formValues.email,
        }),
        ...(this.isRegisterWithSocialAccount ? {
          provider: this.socialProviderResponse.provider,
          image: this.socialProviderResponse.photoUrl
        } : {
          password: formValues.password,
          redirect_url: `${window.location.origin}/${this.appConfig.type}/verify-email`
        }),
      }
    }
    return {};
  }

  loginWithGoogle() {
    this.authSocialService.signOut().then(() => {
      this.authStore.dispatch(SignInWithSocial({
        providerString: GoogleLoginProvider.PROVIDER_ID,
        actionType: 'register'
      }));
    }).catch(() => {
      this.authStore.dispatch(SignInWithSocial({
        providerString: GoogleLoginProvider.PROVIDER_ID,
        actionType: 'register'
      }));
    })
  }

  showPrivacyTerms(activeTab: string) {
    const modelRef: BsModalRef = this.modelService.show(PrivacyTermsComponent, {
      keyboard: true,
      animated: true,
      ignoreBackdropClick: false,
      class: 'modal-xl modal-dialog-centered'
    });
    modelRef.content.activeTab = activeTab;
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
  }

}
