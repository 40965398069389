import {Injectable} from "@angular/core";
import * as SecureLS from "secure-ls";

@Injectable({providedIn: 'root'})
export class LocalstorageService {

  registerUserKey = '[KWOT]-registerUser';
  cartKey = '[KWOT]-cart';
  redirectUrlKey = '[KWOT]-redirectUrl';
  userKey = '[KWOT]-kwotUser';
  countryCodeKey = '[KWOT]-countryCode';
  registerNumberKey = '[KWOT]-registerNumber';
  commonKey = '[KWOT]-commonKey';
  deviceId = '[KWOT]-deviceId';
  recentlyViewProduct = '[KWOT]-recentlyView';
  latestWishList = '[KWOT]-latest wishList';
  geolocationData = '[KWOT]-geolocationData';
  recentlySearch = '[KWOT]-recent search'
  guestUserKey = '[KWOT]-guestUser'
  stripePayment = '[KWOT]-stripePayment'
  isB2B = '[KWOT]-isB2B'
  ls = new SecureLS({encodingType: 'aes'});
  constructor() {
  }

  clearAllLocalStorage() {
    this.updateRegisterUser('remove');
    this.updateRedirectUrl('remove');
    this.updateCountryCode('remove');
    this.updateRegisterNumber('remove');
    this.updateUserKey('remove');
    this.updateCart('remove');
    this.updateCommonKey('remove');
    this.updateGeolocation('remove');
    this.updateRecentSearch('remove');
    this.updateGuestUserKey('remove');
    this.updateStripePayment('remove');
    this.updateWishlist('remove');
    this.updateIsB2B('remove');
  }

  updateRegisterUser(type: string, data: any = null) {
    return this.updateLocalStorage(this.registerUserKey, type, data, true)
  }

  updateCart(type: string, data: any = null) {
    return this.updateLocalStorage(this.cartKey, type, data, true)
  }

  updateWishlist(type: string, data: any = null) {
    return this.updateLocalStorage(this.latestWishList, type, data, true)
  }

  updateRedirectUrl(type: string, data: any = null) {
    return this.updateLocalStorage(this.redirectUrlKey, type, data)
  }

  updateCountryCode(type: string, data: any = null) {
    return this.updateLocalStorage(this.countryCodeKey, type, data)
  }

  updateRegisterNumber(type: string, data: any = null) {
    return this.updateLocalStorage(this.registerNumberKey, type, data)
  }

  updateUserKey(type: string, data: any = null) {
    return this.updateLocalStorage(this.userKey, type, data, true)
  }

  updateCommonKey(type: string, data: any = null) {
    return this.updateLocalStorage(this.commonKey, type, data, true)
  }

  updateDeviceId(type: string, data: any = null) {
    return this.updateLocalStorage(this.deviceId, type, data, true)
  }

  updateRecentlyViewProduct(type: string, data: any = null) {
    return this.updateLocalStorage(this.recentlyViewProduct, type, data, true)
  }

  updateGeolocation(type: string, data: any = null) {
    return this.updateLocalStorage(this.geolocationData, type, data, true)
  }

  updateRecentSearch(type: string, data: any = null) {
    return this.updateLocalStorage(this.recentlySearch, type, data, true)
  }

  updateGuestUserKey(type: string, data: any = null) {
    return this.updateLocalStorage(this.guestUserKey, type, data, true)
  }

  updateStripePayment(type: string, data: any = null) {
    return this.updateLocalStorage(this.stripePayment, type, data, true)
  }

  updateIsB2B(type: string, data: any = null) {
    return this.updateLocalStorage(this.isB2B, type, data)
  }

  private updateLocalStorage(key: string, type: string, data: any = null, isJson = false) {
    if (type === 'store') {
      return this.ls.set(key, isJson ? JSON.stringify(data) : data);
    } else if (type === 'remove') {
      return this.ls.remove(key)
    } else if (type === 'get') {
      const data = this.ls.get(key);
      return isJson ? (data ? JSON.parse(data) : null) : (data || '');
    }
    return null;
  }
}
