import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {EmailPhoneComponent} from "./components/email-phone/email-phone.component";
import {ContactUsComponent} from './components/contact-us/contact-us.component';
import {SharedModule} from "@kwot/shared";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {StoreModule} from "@ngrx/store";
import * as fromVendorBuyer from "./+state/vendor-buyer.reducer";
import {EffectsModule} from "@ngrx/effects";
import {VendorBuyerEffects} from "./+state/vendor-buyer.effects";
import {ShoppingPolicyComponent} from './components/shopping-policy/shopping-policy.component'
import {ConfirmPolicyComponent} from "./components/confirm-policy/confirm-policy.component";
import {PrivacyTermsComponent} from './components/privacy-terms/privacy-terms.component';
import {PaymentPolicyComponent} from "./components/payment-policy/payment-policy.component";
import {RouterModule} from "@angular/router";

@NgModule({
  imports: [
    CommonModule,
    NgxIntlTelInputModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forFeature('vendor-buyer', fromVendorBuyer.reducer),
    EffectsModule.forFeature([VendorBuyerEffects]),
    RouterModule
  ],
  declarations: [
    EmailPhoneComponent,
    ContactUsComponent,
    ShoppingPolicyComponent,
    ConfirmPolicyComponent,
    PrivacyTermsComponent,
    PaymentPolicyComponent
  ],
  exports: [
    EmailPhoneComponent,
    ContactUsComponent,
    ShoppingPolicyComponent,
    ConfirmPolicyComponent,
    PrivacyTermsComponent,
    PaymentPolicyComponent
  ]
})
export class VendorBuyerSharedModule {
}
