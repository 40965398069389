<div class="finances position-relative">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="back-btn d-flex align-items-center" [routerLink]="['/admin', 'dashboard']">
          <img src="/assets/images/icons/back.svg" class="cursor-pointer"/>
          <p class="title-back mb-0 fs-16 cursor-pointer">{{'back' | translate}}</p>
        </div>
        <div>
          <h1 class="fs-32 fw-bold mb-3">Finances</h1>
        </div>
        <div class="tab-content">
          <div class="tab-pane fade show active" id="payout" role="tabpanel" aria-labelledby="payout-tab">
            <div class="finances-list">
              <div class="wallet-balance">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-4 text-center">
                      <h6 class="fs-14">{{'total_revenue' | translate}}</h6>
                      <h2 class="fs-24 fw-bold">
                        {{0 | currencyConvert | async}}
                      </h2>
                    </div>
                    <div class="col-md-4 text-center">
                      <h6 class="fs-14">{{'net_revenue' | translate}}</h6>
                      <h2
                        class="fs-24 fw-bold">{{0 | currencyConvert | async}}</h2>
                    </div>
                    <div class="col-md-4 text-center">
                      <h6 class="fs-14">{{'sellers_profit' | translate}}</h6>
                      <h2
                        class="fs-24 fw-bold">{{0 | currencyConvert | async}}</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container-fluid">
                <div class="row mt-4">
                  <div class="col-md-6"></div>
                  <div class="col-md-4 align-self-center">
                    <div class="form-group mb-0">
                      <div class="left-header d-md-block d-none">
                        <div class="form-group  mb-0">
                          <input type="text"
                                 placeholder="Add currency rate for NGN"
                                 class="form-control br-16" [(ngModel)]="currencyRate">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 align-self-center">
                    <div class="export">
                      <button class="btn-action export-btn"
                              [ngClass]="{'btn-disabled': !currencyRate, 'btn-action': currencyRate}"
                              (click)="currencyRate ? getCSVFile() : ''">Export</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container-fluid">
                <form [formGroup]="dateFilter">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="form-group">
                        <div class="left-header d-md-block d-none">
                          <div class="form-group mb-0">
                            <div class="position-relative">
                              <img src="/assets/images/icons/search-icon.svg" class="search-icon status-search" alt="">
                              <input type="search" class="form-control br-16 search-input"
                                     placeholder="{{'Search name, status' | translate}}"
                                     (keyup)="search($event)">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="form-label fs-12 m-0">{{'Date range' | translate}}</label>
                        <kwot-custom-select
                          class="filter-wallet-data"
                          [searchable]="true"
                          [placeholder]="'Select Date'"
                          [selectOptions]="month"
                          selectedValue="monthRange"
                          (optionSelected)="filterWalletData()"
                          [formGroup]="dateFilter">
                        </kwot-custom-select>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div class="container-fluid">
                <div class="row">
                  <div class="finance-table">
                    <div class="custom-table finances-table">
                      <kwot-custom-table
                        #customTableComponent
                        [customOptions]="customDatatableSettings"
                        [tableHead]="tableHead"
                        [ajaxFunction]="getAllWalletTransaction"
                        [tableBody]="tableBody">
                      </kwot-custom-table>
                      <ng-template #tableHead>
                        <thead>
                        <tr>
                          <td>
                            <p
                              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                              {{'Seller Name' | translate}}
                            </p>
                          </td>
                          <td>
                            <p
                              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                              {{'Transaction’s date' | translate}}
                            </p>
                          </td>
                          <td>
                            <p
                              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                              {{'Amount' | translate}}
                            </p>
                          </td>
                          <td>
                            <p
                              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                              {{'Status' | translate}}
                            </p>
                          </td>
                        </tr>
                        </thead>
                      </ng-template>
                      <ng-template #tableBody>
                        <tbody *ngIf="monthlyTransactionList.length <= 0">
                        <tr>
                          <td colspan="4">{{'no_data_found' | translate}}</td>
                        </tr>
                        </tbody>
                        <tbody *ngIf="monthlyTransactionList.length > 0">

                        <ng-container *ngFor="let transaction of monthlyTransactionList">
                          <tr *ngIf="transaction.type === 'group'; else listTemplate" class="group">
                            <td colspan="4">{{transaction.value}}</td>
                          </tr>
                          <ng-template #listTemplate>
                            <tr>
                              <td>
                                <p class="mb-0 fs-16 store-content-details">
                                  {{transaction?.vendorId?.name}}
                                </p>
                              </td>
                              <td>
                                <p class="mb-0 fs-16 store-content-details">
                                  {{transaction?.createdAt | date: 'dd/MM/yyyy'}}
                                </p>
                              </td>
                              <td>
                                <p class="mb-0 fs-16 store-content-details">
                                  {{ transaction.amount | currencyConvert | async}}
                                </p>
                              </td>
                              <td  *ngIf="transaction.status == 'ON HOLD'">
                                <kwot-custom-select
                                  class="status-data"
                                  [searchable]="true"
                                  placeholder="{{ 'Select status' | translate }}"
                                  (optionSelected)="statusData(transaction._id)"
                                  [selectOptions]="status"
                                  [(selectedValue)]="transaction.status"
                                >
                                </kwot-custom-select>
                              </td>
                              <td *ngIf="transaction.status == 'PENDING' || transaction.status == 'PAID'">
                                <p class="mb-0 fs-16 store-content-details">
                                  {{transaction.status}}
                                </p>
                              </td>
                            </tr>
                          </ng-template>
                        </ng-container>
                        </tbody>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
