<div class="auth-component">
  <span id="close"><img class="" (click)="goLogin()" src="assets/images/icons/Cross.svg"
                        alt=""></span>
  <img class="bg-right--img d-none d-md-block" src="assets/images/bg/auth-Bg.png" alt="">
  <img class="bg-right--img d-md-none" src="assets/images/bg/auth-mobile-Bg.png" alt="">
  <div class="container">
    <div class="row">
      <div class="col-xl-4 col-md-5 pt-56 pt-md-36">
        <div class="action--form">

          <form class="validate-otp-form" (ngSubmit)="!validateForm.invalid && submitForm()" novalidate
                [formGroup]="validateForm">
            <h1 class="register-title text-center">{{'validate_phone_number' | translate}}</h1>
            <h6 class="fw-medium info-text text-center">
              {{'enter_code_sent_to_phone' | translate}} [+{{countryCode}} {{phoneNumber}}]</h6>
            <div class="d-flex otp-fields mt-5">
              <div class="form-group">
                <input type="text" numbersOnly [maxLength]="1" (input)="changeField($event, otpField2)" #otpField1
                       class="form-control br-16 text-center"
                       formControlName="otp1">
              </div>
              <div class="form-group">
                <input type="text" numbersOnly [maxLength]="1" (input)="changeField($event, otpField3)" #otpField2
                       class="form-control br-16 text-center"
                       formControlName="otp2">
              </div>
              <div class="form-group">
                <input type="text" numbersOnly [maxLength]="1" (input)="changeField($event, otpField4)" #otpField3
                       class="form-control br-16 text-center"
                       formControlName="otp3">
              </div>
              <div class="form-group">
                <input type="text" numbersOnly [maxLength]="1" (input)="changeField($event, otpField5)" #otpField4
                       class="form-control br-16 text-center"
                       formControlName="otp4">
              </div>
              <div class="form-group">
                <input type="text" numbersOnly [maxLength]="1" (input)="changeField($event)" #otpField5
                       class="form-control br-16 text-center"
                       formControlName="otp5">
              </div>
            </div>
            <ng-container
              *ngIf="submitted && (form.otp1.errors || form.otp2.errors || form.otp3.errors || form.otp4.errors || form.otp5.errors)">
              <label class="text-danger mb-0 me-2 fs-10 fw-bold">
                {{ 'errors.enter_valid' | translate: {field: 'otp' | translate} }}</label>
            </ng-container>
            <button type="submit" class="mt-4 btn-action w-100 p-14"
                    [ngClass]="{'btn-disabled': validateForm.invalid, 'btn-action': validateForm.valid}">
              {{'validate' | translate}}
            </button>
          </form>
          <div class="text-center pt-4">
            <div class="fw-bold terms-of-use cursor-pointer" (click)="resendCode()">
              <span *ngIf="timer > 0" class="try-again">
                {{'resend_code_after' | translate: {time: timer} }}
              </span>
              <span *ngIf="timer <= 0">
                {{'resend_code' | translate}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
