export const countryCodes = [
  {
    "93": "af"
  },
  {
    "355": "al"
  },
  {
    "213": "dz"
  },
  {
    "684": "as"
  },
  {
    "376": "ad"
  },
  {
    "244": "ao"
  },
  {
    "264": "ai"
  },
  {
    "268": "ag"
  },
  {
    "54": "ar"
  },
  {
    "374": "am"
  },
  {
    "297": "aw"
  },
  {
    "0": "au"
  },
  {
    "43": "at"
  },
  {
    "994": "az"
  },
  {
    "242": "bs"
  },
  {
    "973": "bh"
  },
  {
    "880": "bd"
  },
  {
    "246": "bb"
  },
  {
    "375": "by"
  },
  {
    "32": "be"
  },
  {
    "501": "bz"
  },
  {
    "229": "bj"
  },
  {
    "441": "bm"
  },
  {
    "975": "bt"
  },
  {
    "591": "bo"
  },
  {
    "387": "ba"
  },
  {
    "267": "bw"
  },
  {
    "55": "br"
  },
  {
    "246": "io"
  },
  {
    "284": "vg"
  },
  {
    "673": "bn"
  },
  {
    "359": "bg"
  },
  {
    "226": "bf"
  },
  {
    "257": "bi"
  },
  {
    "855": "kh"
  },
  {
    "237": "cm"
  },
  {
    "204,226,236,249,250,289,306,343,365,387,403,416,418,431,437,438,450,506,514,519,548,579,581,587,604,613,639,647,672,705,709,742,778,780,782,807,819,825,867,873,902,905": "ca"
  },
  {
    "238": "cv"
  },
  {
    "1": "bq"
  },
  {
    "345": "ky"
  },
  {
    "236": "cf"
  },
  {
    "235": "td"
  },
  {
    "56": "cl"
  },
  {
    "86": "cn"
  },
  {
    "2": "cx"
  },
  {
    "1": "cc"
  },
  {
    "57": "co"
  },
  {
    "269": "km"
  },
  {
    "243": "cd"
  },
  {
    "242": "cg"
  },
  {
    "682": "ck"
  },
  {
    "506": "cr"
  },
  {
    "225": "ci"
  },
  {
    "385": "hr"
  },
  {
    "53": "cu"
  },
  {
    "0": "cw"
  },
  {
    "357": "cy"
  },
  {
    "420": "cz"
  },
  {
    "45": "dk"
  },
  {
    "253": "dj"
  },
  {
    "1767": "dm"
  },
  {
    "809,829,849": "do"
  },
  {
    "593": "ec"
  },
  {
    "20": "eg"
  },
  {
    "503": "sv"
  },
  {
    "240": "gq"
  },
  {
    "291": "er"
  },
  {
    "372": "ee"
  },
  {
    "251": "et"
  },
  {
    "500": "fk"
  },
  {
    "298": "fo"
  },
  {
    "679": "fj"
  },
  {
    "0": "fi"
  },
  {
    "33": "fr"
  },
  {
    "594": "gf"
  },
  {
    "689": "pf"
  },
  {
    "241": "ga"
  },
  {
    "220": "gm"
  },
  {
    "995": "ge"
  },
  {
    "49": "de"
  },
  {
    "233": "gh"
  },
  {
    "350": "gi"
  },
  {
    "30": "gr"
  },
  {
    "299": "gl"
  },
  {
    "1473": "gd"
  },
  {
    "0": "gp"
  },
  {
    "671": "gu"
  },
  {
    "502": "gt"
  },
  {
    "1481": "gg"
  },
  {
    "224": "gn"
  },
  {
    "245": "gw"
  },
  {
    "592": "gy"
  },
  {
    "509": "ht"
  },
  {
    "504": "hn"
  },
  {
    "852": "hk"
  },
  {
    "36": "hu"
  },
  {
    "354": "is"
  },
  {
    "91": "in"
  },
  {
    "62": "id"
  },
  {
    "98": "ir"
  },
  {
    "964": "iq"
  },
  {
    "353": "ie"
  },
  {
    "1624": "im"
  },
  {
    "972": "il"
  },
  {
    "0": "it"
  },
  {
    "876": "jm"
  },
  {
    "81": "jp"
  },
  {
    "1534": "je"
  },
  {
    "962": "jo"
  },
  {
    "1": "kz"
  },
  {
    "254": "ke"
  },
  {
    "686": "ki"
  },
  {
    "383": "xk"
  },
  {
    "965": "kw"
  },
  {
    "996": "kg"
  },
  {
    "856": "la"
  },
  {
    "371": "lv"
  },
  {
    "961": "lb"
  },
  {
    "266": "ls"
  },
  {
    "231": "lr"
  },
  {
    "218": "ly"
  },
  {
    "423": "li"
  },
  {
    "370": "lt"
  },
  {
    "352": "lu"
  },
  {
    "853": "mo"
  },
  {
    "389": "mk"
  },
  {
    "261": "mg"
  },
  {
    "265": "mw"
  },
  {
    "60": "my"
  },
  {
    "960": "mv"
  },
  {
    "223": "ml"
  },
  {
    "356": "mt"
  },
  {
    "692": "mh"
  },
  {
    "596": "mq"
  },
  {
    "222": "mr"
  },
  {
    "230": "mu"
  },
  {
    "1": "yt"
  },
  {
    "52": "mx"
  },
  {
    "691": "fm"
  },
  {
    "373": "md"
  },
  {
    "377": "mc"
  },
  {
    "976": "mn"
  },
  {
    "382": "me"
  },
  {
    "664": "ms"
  },
  {
    "0": "ma"
  },
  {
    "258": "mz"
  },
  {
    "95": "mm"
  },
  {
    "264": "na"
  },
  {
    "674": "nr"
  },
  {
    "977": "np"
  },
  {
    "31": "nl"
  },
  {
    "687": "nc"
  },
  {
    "64": "nz"
  },
  {
    "505": "ni"
  },
  {
    "227": "ne"
  },
  {
    "234": "ng"
  },
  {
    "683": "nu"
  },
  {
    "672": "nf"
  },
  {
    "850": "kp"
  },
  {
    "1670": "mp"
  },
  {
    "0": "no"
  },
  {
    "968": "om"
  },
  {
    "92": "pk"
  },
  {
    "680": "pw"
  },
  {
    "970": "ps"
  },
  {
    "507": "pa"
  },
  {
    "675": "pg"
  },
  {
    "595": "py"
  },
  {
    "51": "pe"
  },
  {
    "63": "ph"
  },
  {
    "48": "pl"
  },
  {
    "351": "pt"
  },
  {
    "787,939": "pr"
  },
  {
    "974": "qa"
  },
  {
    "0": "re"
  },
  {
    "40": "ro"
  },
  {
    "0": "ru"
  },
  {
    "250": "rw"
  },
  {
    "1": "bl"
  },
  {
    "290": "sh"
  },
  {
    "1869": "kn"
  },
  {
    "758": "lc"
  },
  {
    "2": "mf"
  },
  {
    "508": "pm"
  },
  {
    "784": "vc"
  },
  {
    "685": "ws"
  },
  {
    "378": "sm"
  },
  {
    "239": "st"
  },
  {
    "966": "sa"
  },
  {
    "221": "sn"
  },
  {
    "381": "rs"
  },
  {
    "248": "sc"
  },
  {
    "232": "sl"
  },
  {
    "65": "sg"
  },
  {
    "721": "sx"
  },
  {
    "421": "sk"
  },
  {
    "386": "si"
  },
  {
    "677": "sb"
  },
  {
    "252": "so"
  },
  {
    "27": "za"
  },
  {
    "82": "kr"
  },
  {
    "211": "ss"
  },
  {
    "34": "es"
  },
  {
    "94": "lk"
  },
  {
    "249": "sd"
  },
  {
    "597": "sr"
  },
  {
    "1": "sj"
  },
  {
    "268": "sz"
  },
  {
    "46": "se"
  },
  {
    "41": "ch"
  },
  {
    "963": "sy"
  },
  {
    "886": "tw"
  },
  {
    "992": "tj"
  },
  {
    "255": "tz"
  },
  {
    "66": "th"
  },
  {
    "670": "tl"
  },
  {
    "228": "tg"
  },
  {
    "690": "tk"
  },
  {
    "676": "to"
  },
  {
    "868": "tt"
  },
  {
    "216": "tn"
  },
  {
    "90": "tr"
  },
  {
    "993": "tm"
  },
  {
    "1649": "tc"
  },
  {
    "688": "tv"
  },
  {
    "340": "vi"
  },
  {
    "256": "ug"
  },
  {
    "380": "ua"
  },
  {
    "971": "ae"
  },
  {
    "0": "gb"
  },
  {
    "0": "us"
  },
  {
    "598": "uy"
  },
  {
    "998": "uz"
  },
  {
    "678": "vu"
  },
  {
    "1": "va"
  },
  {
    "58": "ve"
  },
  {
    "84": "vn"
  },
  {
    "681": "wf"
  },
  {
    "1": "eh"
  },
  {
    "967": "ye"
  },
  {
    "260": "zm"
  },
  {
    "263": "zw"
  },
  {
    "1": "ax"
  }
]
