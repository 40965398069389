import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {LoaderService, LocalstorageService} from "@kwot/app-config";
import {AdminVendorService} from "../services/adminVendor.service";
import {
  GetNotifications,
  GetNotificationsError,
  GetNotificationsSuccess,
  ReadNotification,
  ReadNotificationError,
  ReadNotificationSuccess,
  UpdateNotifications, UpdateNotificationsSuccess, UpdateNotificationsError,
} from "./admin-vendor.actions";
import {catchError, map, of, switchMap} from "rxjs";

@Injectable()
export class AdminVendorEffects {
  constructor(
    private actions$: Actions,
    private loaderService: LoaderService,
    private adminVendorService: AdminVendorService,
    private ls: LocalstorageService
  ) {
  }

  notificationsList$ = createEffect(() => this.actions$.pipe(
    ofType(GetNotifications),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminVendorService.getNotifications(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return GetNotificationsSuccess({notifications: resp.data});
          }
          return GetNotificationsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetNotificationsError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ))

  notificationsRead$ = createEffect(() => this.actions$.pipe(
    ofType(ReadNotification),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminVendorService.readNotification(action.notificationId).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return ReadNotificationSuccess();
          }
          return ReadNotificationError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(ReadNotificationError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ))

  updateNotification$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateNotifications),
    switchMap((action) => {
      return this.adminVendorService.updateNotification(action.params).pipe(
        map((resp: any) => {
          if (resp.status) {
            return UpdateNotificationsSuccess({notificationSeen: resp.data});
          }
          return UpdateNotificationsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(UpdateNotificationsError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));
}
