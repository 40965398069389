import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import {Notifications, User} from "@kwot/data-models";
import {select, Store} from "@ngrx/store";
import {AuthState, LogoutUser} from "@kwot/auth";
import {Router} from "@angular/router";
import {APP_CONFIG, LocalstorageService} from "@kwot/app-config";
import {Subject, takeUntil} from "rxjs";
import {AdminVendorState} from "../../+state/admin-vendor.reducer";
import {getNotifications, getAdminVendorSuccess} from "../../+state/admin-vendor.selectors";
import {GetNotifications, ReadNotification, UpdateNotifications} from "../../+state/admin-vendor.actions";

@Component({
  selector: 'kwot-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input() currentUser: User;
  @Input() userType: 'vendor' | 'admin' = 'admin';
  logoName = '';
  notifications: Notifications[] = [];
  totalNotificationCount = 0;
  viewNotification: any = null;
  isReadForNotification = false;
  currentPage = -1;
  unsubscriber = new Subject();
  notificationSeen: any;
  notiData: any;
  isB2B: boolean = false
  @Input() helpDesk: TemplateRef<any>;

  constructor(
    private authStore: Store<AuthState>,
    private router: Router,
    private localStorageService: LocalstorageService,
    private adminVendorStore: Store<AdminVendorState>,
    @Inject(APP_CONFIG) public appConfig: any
  ) {
  }

  subscribeToStore() {
    this.adminVendorStore.pipe(select(getNotifications))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(notifications => {
        if (notifications) {
          this.totalNotificationCount = notifications?.count;
          this.notificationSeen = notifications?.seen;
          this.notifications = [...this.notifications, ...notifications?.notiData];
        }
      })

    this.adminVendorStore.pipe(select(getAdminVendorSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success && success === '---IGNORE---') {
          if (this.isReadForNotification) {
            this.isReadForNotification = false;
            let noti = this.notifications.findIndex(t => t._id === this.viewNotification._id);
            if (noti !== -1) {
              this.notifications[noti] = {
                ...this.notifications[noti],
                isRead: true
              }
            }
          }
        }
      })
  }

  ngOnInit(): void {
    // if (this.userType === 'vendor') {
    //   this.subscribeToStore();
    //   this.loadNotifications();
    // }
    this.subscribeToStore();
    this.loadNotifications();
    this.isB2B = this.localStorageService.updateIsB2B('get');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentUser'] && changes['currentUser'].currentValue) {
      this.changeNames();
    }
    if (changes['userType'] && changes['userType'].currentValue) {
      this.changeNames();
    }
  }

  loadNotifications() {
    this.currentPage = this.currentPage + 1;
    this.adminVendorStore.dispatch(GetNotifications({
      params: {page: this.currentPage, limit: 4}
    }))
  }

  ngAfterViewInit() {
    let doc = document.getElementById('account-menu');
    if (doc) {
      doc.addEventListener('click', function (e) {
        e.stopPropagation();
      })
    }
  }

  changeNames() {
    if (this.userType === 'admin') {
      this.logoName = this.currentUser?.firstName[0] + (this.currentUser?.lastName ? this.currentUser?.lastName[0] : '')
    } else {
      let [firstName, lastName] = this.currentUser?.name?.split(' ');
      this.logoName = firstName[0] + (lastName ? lastName[0] : '')
    }
  }

  openDropDown() {
    this.notificationSeen = true;
    this.adminVendorStore.dispatch(UpdateNotifications({
      params: {notification_seen: this.notificationSeen}
    }))

  }

  showNotification(notification: any) {
    this.viewNotification = notification;
    if (!notification.isRead) {
      this.isReadForNotification = true;
      this.adminVendorStore.dispatch(ReadNotification({notificationId: notification._id}));
    }
  }

  enableChanged() {
    this.isB2B = !this.isB2B
    if (this.isB2B) {
      this.localStorageService.updateIsB2B('store', this.isB2B);
    } else {
      this.localStorageService.updateIsB2B('remove');
    }
    window.location.reload();
  }

  logoutUser() {
    this.localStorageService.clearAllLocalStorage();
    this.authStore.dispatch(LogoutUser());
    this.router.navigate([`/${this.userType}/login`]);
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
  }

}
