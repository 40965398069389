import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdminDashboard, User} from "@kwot/data-models";
import {select, Store} from "@ngrx/store";
import {AdminState} from "../../+state/admin.reducer";
import {getAdminDashboard} from "../../+state/admin.selectors";
import {Subject, takeUntil} from "rxjs";
import {Dashboard, ResetAdminState} from "../../+state/admin.actions";
import {AuthState, getLoggedInUser} from "@kwot/auth";
import * as moment from "moment";

@Component({
  selector: 'kwot-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  public chartOptions: any;
  adminDashboard: AdminDashboard = null;
  unsubscriber = new Subject();
  currentUser: User;
  month: any[] = [
    {
      name: 'Current Week',
      id: `${moment().startOf('week').format('YYYY-MM-DD')}/${moment().format('YYYY-MM-DD')}/CW`
    },
    {
      name: 'Current Month',
      id: `${moment().startOf('month').format('YYYY-MM-DD')}/${moment().format('YYYY-MM-DD')}/CM`
    },
    {
      name: 'Current Year',
      id: `${moment().startOf('year').format('YYYY-MM-DD')}/${moment().format('YYYY-MM-DD')}/CY`
    },
    {
      name: 'Last Week',
      id: `${moment().startOf('week').subtract(7, 'days').format('YYYY-MM-DD')}/${moment().endOf('week').subtract(7, 'day').format('YYYY-MM-DD')}/LW`
    },
    {
      name: 'Last Month',
      id: `${moment().startOf('month').subtract(1, 'month').format('YYYY-MM-DD')}/${moment().endOf('month').subtract(1, 'month').format('YYYY-MM-DD')}/LM`
    },
    {
      name: 'Last Year',
      id: `${moment().startOf('year').subtract(1, 'year').format('YYYY-MM-DD')}/${moment().endOf('year').subtract(1, 'year').format('YYYY-MM-DD')}/LY`
    },
  ];
  orders: any = [];
  totalPrice = 0;
  monthRange: any;
  dates: any = [];
  range: any;
  chartData: any[] = [];
  profit = 0;
  netRevenue = 0;

  constructor(
    private adminStore: Store<AdminState>,
    private authStore: Store<AuthState>,
  ) {
    this.chartOptions = {
      series: [],
      grid: {
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        },
        padding: {
          left: 22,
        }
      },
      chart: {
        type: "line",
        width: '100%',
        height: 180,
        toolbar: {
          show: false
        },
        redrawOnParentResize: true,
        redrawOnWindowResize: true
      },
      colors: ['#000000'],
      stroke: {
        curve: 'smooth',
        colors: ['#000000'],
        width: 4,
      },
      xaxis: {
        type: 'category',
        categories: [],
        axisBorder: {
          show: true,
          color: '#D1D6DC',
          height: 1,
          width: '100%',
          offsetX: -22,
          offsetY: 0
        },
        labels: {
          show: true,
          style: {
            colors: '#727981'
          }
        },
        tickAmount: 7,
        axisTicks: {
          show: true
        },
      },
      yaxis: {
        axisTicks: {
          show: false
        },
        forceNiceScale: true,
        min: 0,
        labels: {
          show: false,
        },
        axisBorder: {
          show: true,
          color: '#D1D6DC',
          height: 1,
          offsetX: -15,
          offsetY: 0
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: (seriesData: any) => {
            return parseInt(seriesData);
          }
        }
      }
    }
    this.monthRange = `${moment().startOf('month').format('YYYY-MM-DD')}/${moment().format('YYYY-MM-DD')}/CM`
    this.adminStore.dispatch(ResetAdminState({params: {error: '', success: '', dashboard: null}}));
    this.subscribeToStore();
  }

  subscribeToStore() {
    this.adminStore.pipe(select(getAdminDashboard))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(dashboard => {
        if (dashboard) {
          this.adminDashboard = dashboard;
          this.chartData = [...dashboard.graphData];

          this.orders = {};
          this.totalPrice = 0;
          this.profit = 0;
          this.netRevenue = 0;
          this.chartData.forEach((data) => {
            let format  = (this.range == 'CY' || this.range == 'LY') ? 'MM-YYYY' : 'D MMM';
            let date = moment(data.createdAt).format(format)
            this.totalPrice += Number((data.price * data.quantity).toFixed(2))
            if (!this.orders[date]) {
              this.orders[date] = 0;
            }
            this.orders[date] += 1;
          })

          this.adminDashboard.profit.forEach((data: any) => {
            this.profit += Number(data.productOriginalPrice.toFixed(2))
          })

          this.netRevenue = this.totalPrice - this.profit

          let series = [];
          let data: any = [];
          this.dates.forEach((each: any) => {
            data.push(parseInt(this.orders[each] || 0));
          });

          series.push({
            name: 'Orders',
            data: data,
          })

          this.chartOptions.series = series
        }
      })

    this.authStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(currentUser => {
        if (currentUser) {
          this.currentUser = currentUser;
        }
      })
  }

  ngOnInit(): void {
    this.adminStore.dispatch(Dashboard({
      params: {
        monthRange: this.monthRange
      }
    }));
    const date = this.monthRange
    this.getDates(date)
  }

  filterData(event: any) {
    this.adminStore.dispatch(Dashboard({
      params: {
        monthRange: event.value || this.monthRange
      }
    }))
    this.getDates(event.value || this.monthRange)
  }

  getDates(date: any) {
    this.dates = [];
    let [start, end, range] = date.split('/');
    start = moment(start);
    end = moment(end);
    this.range = range;

    if (this.range == 'CY' || this.range == 'LY') {
      while (start.isBefore(end)) {
        this.dates.push(start.format("MM-YYYY"));
        start = start.add(1, "month");
      }
    } else {
      this.dates.push(start.format('D MMM'))
      while (start.add(1, 'days').diff(end) < 0) {
        this.dates.push(moment(start.clone().toDate()).format('D MMM'));
      }
      this.dates.push(end.format('D MMM'))
    }

    this.chartOptions.xaxis = {
      ...this.chartOptions.xaxis,
      categories: this.dates
    }
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
  }

}
