<div class="orders-list">
  <div class="orders-list-title">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div class="back d-flex align-items-center">
          <img src="/assets/images/icons/back.svg" class="cursor-pointer  d-md-block d-none"
               [routerLink]="['/admin', 'dashboard']"/>
          <p class="title-back mb-0 fs-16 cursor-pointer  d-md-block d-none"
             [routerLink]="['/admin', 'dashboard']">{{'back' | translate}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="orders-list-wrapper">
    <h2 class="fs-32 fw-bold ps-sm-0 ps-4">{{'orders' | translate}}</h2>
    <div class="custom-table">
      <div class="tag-filters">
        <span class="tag" [ngClass]="{'active': selectedFilter === filter.value}"
              (click)="filterOrders(filter.value)"
              *ngFor="let filter of filters">{{filter.label}}</span>
      </div>
      <kwot-custom-table
        #customTableComponent
        [customOptions]="customDatatableSettings"
        [ajaxFunction]="getAllOrders"
        [tableHead]="tableHead" [tableBody]="tableBody">
      </kwot-custom-table>
      <ng-template #tableHead>
        <thead *ngIf="!isMobile">
        <tr>
          <td>
            <p
              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
              {{'buyer' | translate}}
            </p>
          </td>
          <td>
            <p
              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
              {{'seller' | translate}}
            </p>
          </td>
          <td>
            <p
              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
              {{'order' | translate}}
            </p>
          </td>
          <td>
            <p
              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
              {{'date_time' | translate}}
            </p>
          </td>
          <td>
            <p
              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
              {{'status' | translate}}
            </p>
          </td>
          <td>
            <p
              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
              {{'products' | translate}}
            </p>
          </td>
          <td>
            <p
              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
              {{'price' | translate}}
            </p>
          </td>
        </tr>
        </thead>
      </ng-template>
      <ng-template #tableBody>
        <tbody *ngIf="ordersList.length <= 0">
        <tr>
          <td colspan="7">{{'no_data_found' | translate}}</td>
        </tr>
        </tbody>
        <tbody *ngIf="ordersList.length > 0  && !isMobile">
        <tr *ngFor="let order of ordersList">
          <td>
            <p class="mb-0 fs-16 cursor-pointer store-content-details"
               [routerLink]="['/admin', 'orders', 'order-info', order._id]">
              {{order?.user?.firstName}} {{order?.user?.lastName}}
            </p>
          </td>
          <td>
            <p class="mb-0 fs-16 cursor-pointer store-content-details"
               [routerLink]="['/admin', 'orders', 'order-info', order._id]">
              <img [lazyLoad]="order.seller?.logo || order.seller?.image"
                   [defaultImage]="'/assets/images/seller_logo.png'" class="search-icon me-2" alt="">
              {{order.seller?.name}}
            </p>
          </td>
          <td>
            <p class="mb-0 fs-16 store-content-details">
              #{{order.orderNo}}
            </p>
          </td>
          <td>
            <p class="mb-0 fs-16 store-content-details">
              {{order.createdAt | date: 'dd/MM/yyyy'}}
            </p>
          </td>
          <td>
            <p class="mb-0 fs-16 store-content-details">
              <span class="status {{orderStatus[order.status] | slugify}}" *ngIf="!order?.isReturning">
                <span
                  class="badge"></span>{{order.status == 5 ? 'Canceled By Buyer' : order.status == 2 ? 'Canceled By Vendor' : orderStatus[order.status]}}
              </span>
              <span class="status {{returnOrderStatus[order.returnStatus] | slugify}}" *ngIf="order?.isReturning">
                <span class="badge"></span>{{returnOrderStatus[order.returnStatus]}}
              </span>
            </p>
          </td>
          <td>
            <p class="mb-0 fs-16 store-content-details">
              {{order?.product?.productName}}
            </p>
          </td>
          <td>
            <p class="mb-0 fs-16 store-content-details">
              {{(order.productOriginalPrice * order.quantity) | currencyConvert | async}}
            </p>
          </td>
        </tr>
        </tbody>
        <tbody *ngIf="ordersList.length > 0  && isMobile">
        <tr *ngFor="let order of ordersList">
          <td colspan="9" class="order-details w-100 p-3">
            <div>
              <p class="mb-0 fs-16 cursor-pointer store-content-details"
                 [routerLink]="['/admin', 'orders', 'order-info', order._id]">
                {{order?.user?.firstName}} {{order?.user?.lastName}}
              </p>
            </div>
            <div>
              <div class="d-flex">
                <p class="mb-0 fs-16 store-content-details">
                  #{{order.orderNo}}
                </p>
                <p class="mb-0 fs-16 store-content-details ms-2">
                  {{order.createdAt | date: 'dd/MM/yyyy'}}
                </p>
                <p class="mb-0 fs-16 store-content-details w-100 ms-auto justify-content-end">
              <span class="status {{orderStatus[order.status] | slugify}}" *ngIf="!order?.isReturning">
                <span
                  class="badge"></span>{{order.status == 5 ? 'Canceled By Buyer' : order.status == 2 ? 'Canceled By Vendor' : orderStatus[order.status]}}
              </span>
                  <span class="status {{returnOrderStatus[order.returnStatus] | slugify}}" *ngIf="order?.isReturning">
                <span class="badge"></span>{{returnOrderStatus[order.returnStatus]}}
              </span>
                </p>
              </div>
              <p class="mb-0 fs-16 cursor-pointer store-content-details"
                 [routerLink]="['/admin', 'orders', 'order-info', order._id]">
                <img [lazyLoad]="order.seller?.logo || order.seller?.image"
                     [defaultImage]="'/assets/images/seller_logo.png'" class="search-icon me-2" alt="">
                {{order.seller?.name}}
              </p>
            </div>
            <div class="d-flex">
              <p class="mb-0 fs-16 store-content-details">
                {{order?.product?.productName}}
              </p>
              <p class="mb-0 fs-16 store-content-details ms-auto w-100 justify-content-end">
                {{(order.productOriginalPrice * order.quantity) | currencyConvert | async}}
              </p>
            </div>
          </td>
        </tr>
        </tbody>
      </ng-template>
    </div>
  </div>
</div>
