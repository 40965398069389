import {Inject, Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Router} from "@angular/router";
import {catchError, Observable, switchMap, take, throwError} from "rxjs";
import {select, Store} from "@ngrx/store";
import {AuthState, getToken} from "../../auth/src";
import {LocalstorageService} from "./localstorage.service";
import {APP_CONFIG} from "../index";

@Injectable()
export class HttpAuthInterceptService implements HttpInterceptor {
  inflightAuthRequest: any = null;

  constructor(
    private router: Router,
    private authStore: Store<AuthState>,
    private localStorageService: LocalstorageService,
    @Inject(APP_CONFIG) private appConfig: any
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newHeaders = new HttpHeaders();
    const hasAccept: boolean = request.headers.has('Accept');
    if (!hasAccept) {
      newHeaders = newHeaders.append('Accept', 'application/json');
    }
    const hasContentType: boolean = request.headers.has('Content-Type');
    if (!hasContentType) {
      newHeaders = newHeaders.append('Content-Type', 'application/json; charset=utf-8');
    }
    const hasTempAuth: boolean = request.headers.has('AddTempAuth');
    if (hasTempAuth) {
      let registerUser = this.localStorageService.updateRegisterUser('get');
      let token = '';
      if (registerUser) {
        token = registerUser.token;
      } else {
        registerUser = this.localStorageService.updateUserKey('get');
        if (registerUser) {
          token = registerUser.token;
        }
      }
      newHeaders = newHeaders.append('Authorization', `Bearer ${token}`);
    }
    const isB2B = this.localStorageService.updateIsB2B('get');
    if (this.appConfig.type === 'admin' && isB2B) {
      newHeaders = newHeaders.append('isB2B', 'true');
    }
    if (this.appConfig.type !== 'admin') {
      if (this.appConfig.applicationType === 'b2b') {
        newHeaders = newHeaders.append('isB2B', 'true');
      }
    }
    let basicAuthRequest = request.clone({headers: newHeaders});
    let hasSkipAuth: boolean = request.headers.has('Skip-auth');

    if (!this.inflightAuthRequest) {
      this.inflightAuthRequest = this.authStore.pipe(select(getToken));
    }

    return this.inflightAuthRequest.pipe(
      take(1),
      switchMap((newToken: string) => {
        this.inflightAuthRequest = null;
        let token = newToken || '';
        if (!hasSkipAuth) {
          if (this.appConfig.type === "user" && !token) {
            let registerUser = this.localStorageService.updateGuestUserKey('get');
            if (registerUser && !token) {
              token = registerUser.token;
              return next.handle(basicAuthRequest.clone({
                headers: basicAuthRequest.headers.append('Authorization', `Bearer ${token}`)
              }));
            }
            const deviceId = this.localStorageService.updateDeviceId('get');
            if (deviceId) {
              return next.handle(basicAuthRequest.clone({
                headers: basicAuthRequest.headers.append('deviceId', deviceId)
              }));
            }
          }
          return next.handle(basicAuthRequest.clone({
            headers: basicAuthRequest.headers.append('Authorization', `Bearer ${token}`)
          }));
        } else {
          return next.handle(basicAuthRequest);
        }
      }),
      catchError(error => {
        let unauthorized = false;
        let jwtExpired = false;
        if (error && error.statusText) {
          unauthorized = !!error.statusText.match(/Unauthorized/g);
        }
        if (error && error.error.message === 'jwt expired') {
          jwtExpired = true;
        }
        if (error.status === 401 || unauthorized || jwtExpired) {
          this.localStorageService.updateRegisterUser('remove');
          this.localStorageService.updateUserKey('remove');
          this.router.navigate(['/', this.appConfig.type, 'login']);
        }
        return throwError(error);
      }) as any
    )
  }
}
