import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {select, Store} from "@ngrx/store";
import {
  AuthState,
  SignInWithSocial,
  ResetAuthState,
  getSignInWithSocialUser, Login, getLoggedInUser
} from "@kwot/auth";
import {GoogleLoginProvider, SocialAuthService} from "angularx-social-login";
import {Subject, takeUntil} from "rxjs";
import {SocialUser, User} from "@kwot/data-models";
import {Router} from "@angular/router";
import {APP_CONFIG, LocalstorageService} from "@kwot/app-config";
import {Title} from "@angular/platform-browser";


@Component({
  selector: 'kwot-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: UntypedFormGroup;
  isRegisterWithPhoneNumber = false;
  showPassword = false;
  unsubscriber = new Subject();
  socialProviderResponse: SocialUser;

  constructor(
    private authStore: Store<AuthState>,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private authSocialService: SocialAuthService,
    @Inject(APP_CONFIG) public appConfig: any,
    private localStorageService: LocalstorageService,
    private titleService: Title
  ) {
    this.localStorageService.clearAllLocalStorage();
    this.authStore.dispatch(ResetAuthState({
      params: {
        error: '',
        success: '',
        signInUserSocial: null
      }
    }));
    this.subscribeToStore();
  }

  subscribeToStore() {
    this.authStore.pipe(select(getSignInWithSocialUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(registerUserSocial => {
        if (registerUserSocial) {
          this.socialProviderResponse = registerUserSocial;
          this.changeLoginType(false);
          this.loginForm.patchValue({
            password: '',
            email: registerUserSocial.email
          });
          this.loginForm.get('password')?.clearValidators();
          this.loginForm.get('password')?.updateValueAndValidity();
          this.submitForm(true);
        }
      })
    this.authStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(currentUser => {
        if (currentUser) {
          this.localStorageService.updateUserKey('store', currentUser);
          // const route = this.localStorageService.updateRedirectUrl('get');
          if (this.appConfig.type === 'vendor' || this.appConfig.type === 'user') {
            if (currentUser.isDeleted) {
              this.localStorageService.updateCommonKey('store', {redirect: false});
              this.router.navigate([`/${this.appConfig.type}`, 'reactivate-account']);
              return;
            }
            if (currentUser.email && !currentUser.isEmailVerified) {
              this.localStorageService.updateCommonKey('store', {redirect: false});
              this.router.navigate([`/${this.appConfig.type}`, 'email-verification']);
              return;
            }
            if (currentUser.phone && !currentUser.isPhoneVerified && !currentUser.isEmailVerified) {
              this.localStorageService.updateCommonKey('store', {redirect: false});
              this.localStorageService.updateRegisterNumber('store', currentUser.phone || '');
              this.localStorageService.updateCountryCode('store', currentUser.phoneCountry || '');
              this.localStorageService.updateRegisterUser('store', currentUser);
              this.router.navigate(['/', this.appConfig.type, 'validate-otp'])
              return;
            }
          }
          if (this.appConfig.type === 'admin') {
            this.router.navigate([`/${this.appConfig.type}`, 'dashboard']);
          } else if (this.appConfig.type === 'vendor') {
            if (currentUser.profileStatus >= 7) {
              this.router.navigate([`/${this.appConfig.type}`, 'dashboard']);
            } else {
              this.router.navigate([`/${this.appConfig.type}`, 'store-setup']);
            }
          } else if (this.appConfig.type === 'user') {
            this.router.navigate([`/${this.appConfig.type}`, 'home']);
            return;
          }
        }
      })
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(RegExp(/^[a-zA-z0-9._%+-]+@[a-zA-z0-9.-]+\.[a-zA-z]{2,4}$/))]],
      phone: [''],
      password: ['', Validators.required],
    })

    if (this.appConfig.type === 'user') {
      this.titleService.setTitle('Kwot - Amazing Africa. Now, delivered at your doorstep');
    }
  }

  changeLoginType(isWithPhone: boolean) {
    this.isRegisterWithPhoneNumber = isWithPhone;
    if (isWithPhone) {
      this.loginForm.patchValue({email: '', phone: ''});
      this.loginForm.get('email')?.clearValidators();
      this.loginForm.get('phone')?.setValidators(Validators.required);
    } else {
      this.loginForm.patchValue({email: '', phone: ''});
      this.loginForm.get('phone')?.clearValidators();
      this.loginForm.get('email')?.setValidators([Validators.required, Validators.pattern(RegExp(/^[a-zA-z0-9._%+-]+@[a-zA-z0-9.-]+\.[a-zA-z]{2,4}$/))]);
    }
    this.loginForm.get('email')?.updateValueAndValidity();
    this.loginForm.get('phone')?.updateValueAndValidity();
  }

  get form() {
    return this.loginForm.controls;
  }

  submitForm(isWithSocial = false) {

    if (this.loginForm.invalid) {
      return;
    }

    const formValues = {...this.loginForm.value};
    const body: User = {
      ...(this.isRegisterWithPhoneNumber ? {
        phone: (formValues.phone?.number || '').replace(/\s/g, ''),
      } : {email: formValues.email}),
      ...(isWithSocial ? {
        provider: this.socialProviderResponse.provider,
        name: `${this.socialProviderResponse.firstName} ${this.socialProviderResponse.lastName}`,
        image: this.socialProviderResponse.photoUrl
      } : {
        password: formValues.password,
      })
    };
    this.authStore.dispatch(Login({user: body}));
    this.localStorageService.updateWishlist('remove');
  }

  loginWithGoogle() {
    this.authSocialService.signOut().then(() => {
      this.authStore.dispatch(SignInWithSocial({
        providerString: GoogleLoginProvider.PROVIDER_ID,
        actionType: 'login'
      }));
    }).catch(() => {
      this.authStore.dispatch(SignInWithSocial({
        providerString: GoogleLoginProvider.PROVIDER_ID,
        actionType: 'login'
      }));
    })
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
  }

}
