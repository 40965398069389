<div class="container">
  <div class="row justify-content-center">
    <span class="close cursor-pointer mt-2" (click)="goLogin()">
    <img class="float-end" src="assets/images/icons/Cross.svg" alt=""/>
  </span>
    <div class="col-lg-6 col-md-7 col-sm-9 col-12 verify-content">
      <div class="verifyemail mt-3">
        <div class="img-wrapper ms-auto text-center">
          <img class="img-fluid w-50" src="assets/images/no-product-img.png" alt=""/>
        </div>
        <h1 class="text-center py-3">It seems that your account had been deleted</h1>
        <p class="text-center">
          Do you want to re-activate your account and retrieve your information?
        </p>
        <button type="submit" class="btn-green-border w-100 p-14"
                (click)="goRegister()">
          Create new account
        </button>
        <button type="submit" class="my-4 btn-action w-100 p-14"
                (click)="verifyData()">
          Re-activate my account
        </button>
      </div>
    </div>
  </div>
</div>
