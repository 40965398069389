<div class="container">
  <div class="row justify-content-center">
    <span class="close cursor-pointer mt-2" (click)="goLogin()">
    <img class="float-end" src="assets/images/icons/Cross.svg" alt=""/>
  </span>
    <div class="col-lg-5 col-md-7 col-sm-9 col-12">
      <div class="verifyemail mt-3">
        <div class="img-wrapper ms-auto text-center">
          <img class="img-fluid" src="assets/images/bg/email_img.png" alt=""/>
        </div>
        <h1 class="text-center py-3" *ngIf="!type">Verify your email</h1>
        <h1 class="text-center py-3" *ngIf="type">Email link expired</h1>
        <p class="text-center" *ngIf="!type">
          We have sent an email to <strong>{{email}}</strong>
        </p>
        <p class="text-center" *ngIf="!type">
          You need to verify your email to continue.
          If you have not received the verification email, please check
          your "Spam" or "Bulk Email" folder. You can also click the
          resend button below to have another email sent to you.
        </p>
        <p class="text-center" *ngIf="type">
          Your verification link is expired.
          Please click the resend button below to have another email sent to you.
        </p>
        <button type="submit" class="my-4 btn-action w-100 p-14"
                (click)="verifyData()">
          Resend verification link
        </button>
      </div>
    </div>
  </div>
</div>
