import {Inject, Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {APP_CONFIG} from "@kwot/app-config";

@Injectable({
  providedIn: "root"
})
export class CommonService {
  ApiUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: any
  ) {
    this.ApiUrl = `${this.appConfig.apiUrl}/`;
  }

  getAwsSignedUrl(fileName: any, contentType: any, folderPath: any) {
    const headers = new HttpHeaders({'Skip-auth': 'true'});
    return this.http.get(`${this.ApiUrl}upload/getSignedUrl`, {params: {fileName, contentType, folderPath}, headers});
  }

  uploadFileToSignedUrl(url: any, fileData: any) {
    const headers = new HttpHeaders({'Skip-auth': 'true', 'Content-Type': fileData.type});
    return this.http.put(url, fileData, {headers, reportProgress: true, observe: 'events'});
  }

  getResizeImage(url: any, width: any, height: any, quality: any, folderPath: any, type: any) {
    const headers = new HttpHeaders({'Skip-auth': 'true'});
    return this.http.get(`${this.ApiUrl}upload/resizeImage`, {params: {url, width, height, quality, folderPath, type}, headers});
  }

  createPaymentIntent(body: any) {
    const headers = new HttpHeaders({'Skip-auth': 'true'});
    return this.http.post(`${this.ApiUrl}${this.appConfig.type}/createPaymentIntent`, body, {headers});
  }

  getDHLShippingRate(body: any) {
    // const headers = new HttpHeaders({'Skip-auth': 'true'});
    return this.http.post(`${this.ApiUrl}${this.appConfig.type}/getRatesForMultipleProduct`, body);
  }

  getConfirmPayment(params: any) {
    const headers = new HttpHeaders({'Skip-auth': 'true'});
    return this.http.get(this.appConfig.interSwitchPaymentConfirmUrl, {headers, params});
  }

  saveMeasurements(body: any) {
    return this.http.post(`${this.ApiUrl}${this.appConfig.type}/saveMeasurements`, body);
  }

  getConversationRates() {
    return this.http.get(`${this.ApiUrl}public/getCurrencyRates`);
  }

  getImage(url: any, params: any) {
    const headers = new HttpHeaders({'Skip-auth': 'true'});
    const accept = new HttpHeaders({'Skip-auth': 'true'});
    return this.http.get(url, {headers})
  }

}
