import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Order, OrderStatusText, ReturnOrderStatusText} from "@kwot/data-models";
import {Subject, takeUntil} from "rxjs";
import {CustomTableComponent} from "@kwot/admin-vendor-shared";
import {select, Store} from "@ngrx/store";
import {AdminState} from "../../+state/admin.reducer";
import {OrdersList, ResetAdminState} from "../../+state/admin.actions";
import {getAllOrders} from "../../+state/admin.selectors";
import {TranslateService} from "@ngx-translate/core";
import {WindowService} from "../../../../../../libs/shared/src/lib/services/window.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'kwot-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss']
})
export class OrdersListComponent implements OnInit, OnDestroy {

  ordersList: Order[] = [];
  filters: any[] = [
    {
      value: '',
      label: 'All',
    },
    {
      value: 'pending',
      label: 'Pending',
    },
    {
      value: 'shipped',
      label: 'Shipped',
    },
    {
      value: 'return',
      label: 'Return request',
    },
    {
      value: 'canceled',
      label: 'Canceled',
    },
  ];
  selectedFilter: string | number = '';
  unsubscriber = new Subject();
  customDatatableSettings: any = {};
  orderStatus = OrderStatusText;
  returnOrderStatus = ReturnOrderStatusText;
  isMobile: boolean;
  screenSize:number;

  @ViewChild('customTableComponent', {static: true}) customTableComponent: CustomTableComponent;

  constructor(
    private adminStore: Store<AdminState>,
    private translateService: TranslateService,
    private windowService: WindowService,
    private activeRoute: ActivatedRoute
  ) {
    this.adminStore.dispatch(ResetAdminState({params: {error: '', success: '', orders: null}}));
    this.subscribeToStore();
    this.windowService.onResize$.subscribe((data) => {
      this.isMobile = data.width <= 767;
    });
    this.activeRoute.queryParams.subscribe(query => {
      if (query.return) {
        this.selectedFilter = 'return'
      }
    })
  }

  subscribeToStore() {
    this.adminStore.pipe(select(getAllOrders))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(orders => {
        if (orders) {
          this.ordersList = [...orders.data];
          if (this.customTableComponent.ajaxCallback) {
            this.customTableComponent.ajaxCallback({
              recordsTotal: orders.count.total,
              recordsFiltered: orders.count.total,
              data: []
            })

            setTimeout(() => {
              this.customTableComponent.reAdjustColumnsInTable();
            }, 500);
          }
        }
      })
  }

  ngOnInit(): void {
    this.customDatatableSettings = {
      paging: true,
      serverSide: true,
      columns: [{data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null}],
      addAjax: true,
      order: [[2, 'asc']],
      searchPlaceholder: this.translateService.instant('search_by', {field: 'seller'}),
      orderColumns: ['buyer', 'seller', 'order', 'date_time', 'status', 'products', 'price']
    }
    this.screenSize = this.windowService.getScreenSize();
    this.isMobile = this.screenSize <= 767;
  }

  getAllOrders = (page = 0, perPage = 10, search = '', order = {}) => {
    this.adminStore.dispatch(OrdersList({
      params: {
        page, limit: perPage,
        ...(search ? {search} : {}),
        ...(this.selectedFilter ? {criteria: this.selectedFilter} : {}),
        ...order
      }
    }))
  }

  filterOrders(value: string | number) {
    this.selectedFilter = value
    this.getAllOrders(undefined, undefined, undefined, undefined);
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete()
  }
}
