import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {LoaderService} from "@kwot/app-config";
import {
  Dashboard,
  DashboardError,
  DashboardSuccess,
  OrdersList,
  OrdersListError,
  OrdersListSuccess,
  GetCsvFile,
  GetCsvFileSuccess,
  GetCsvFileError,
  GetPayoutList,
  GetPayoutListError,
  GetPayoutListSuccess,
  UpdatePayoutStatusError,
  UpdatePayoutStatus,
  UpdatePayoutStatusSuccess,
  GetReviews,
  GetReviewsSuccess,
  GetReviewsError,
  DeleteReviews,
  DeleteReviewsSuccess,
  DeleteReviewsError,
} from "./admin.actions";
import {catchError, map, of, switchMap} from "rxjs";
import {AdminService} from "../services/admin.service";
import * as moment from "moment";

@Injectable()
export class AdminEffects {
  constructor(
    private actions$: Actions,
    private loaderService: LoaderService,
    private adminService: AdminService
  ) {
  }

  dashboard$ = createEffect(() => this.actions$.pipe(
    ofType(Dashboard),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.getDashboard(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return DashboardSuccess({dashboard: resp.data});
          }
          return DashboardError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(DashboardError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ))

  ordersList$ = createEffect(() => this.actions$.pipe(
    ofType(OrdersList),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.getAllOrders(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return OrdersListSuccess({orders: resp.data || {data: [], count: {total: 0, page: 0}}});
          }
          return OrdersListError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(OrdersListError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ))

  getCsvFile$ = createEffect(() => this.actions$.pipe(
    ofType(GetCsvFile),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.getCsvFile(action.rate).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            const date = moment().format("YYYYMMDD-hhmm");
            const linkSource = `data:text/csv;charset=utf-8,${resp.data}`;
            const downloadLink = document.createElement('a');
            const fileName = `KWOT-PAYMENT-${date}.csv`;

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
            return GetCsvFileSuccess({csvFile: resp.data});
          }
          return GetCsvFileError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetCsvFileError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ))

  getPayout$ = createEffect(() => this.actions$.pipe(
    ofType(GetPayoutList),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.getPayout(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return GetPayoutListSuccess({payout: resp.data});
          }
          return GetPayoutListError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetPayoutListError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  updatePayoutStatus$ = createEffect(() => this.actions$.pipe(
    ofType(UpdatePayoutStatus),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.updatePayoutStatus(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return UpdatePayoutStatusSuccess({payout: resp.data});
          }
          return UpdatePayoutStatusError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(UpdatePayoutStatusError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  getReviews$ = createEffect(() => this.actions$.pipe(
    ofType(GetReviews),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.getReviews(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return GetReviewsSuccess({reviews: resp.data});
          }
          return GetReviewsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetReviewsError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  deleteReviews$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteReviews),
    switchMap((action) => {
      this.loaderService.show();
      return this.adminService.deleteReview(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return DeleteReviewsSuccess({id: action.params.id});
          }
          return DeleteReviewsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(DeleteReviewsError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

}
