import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {RegisterComponent} from './components/register/register.component';
import {ReactiveFormsModule} from '@angular/forms';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import * as fromAuth from './+state/auth.reducer';
import {AuthEffects} from './+state/auth.effects';
import {GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule} from "angularx-social-login";
import {LoginComponent} from './components/login/login.component';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import {ValidateOtpComponent} from './components/validate-otp/validate-otp.component';
import {SharedModule} from "@kwot/shared";
import {AuthComponent} from './auth.component';
import {RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings} from "ng-recaptcha";
import {VerifyEmailComponent} from './components/verify-email/verify-email.component';
import {ResetVerificationComponent} from './components/reset-verification/reset-verification.component';
import {ReactivateAccountComponent} from "./components/reactivate-account/reactivate-account.component";
import {VendorBuyerSharedModule} from "@kwot/vendor-buyer-shared";

const authRoutes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: 'login'},
  {
    path: '',
    component: AuthComponent,
    children: [
      {path: 'register', component: RegisterComponent},
      {path: 'login', component: LoginComponent},
      {path: 'forgot-password', component: ForgotPasswordComponent},
      {path: 'reset-password', component: ResetPasswordComponent},
      {path: 'email-verification', component: ResetVerificationComponent},
      {path: 'validate-otp', component: ValidateOtpComponent},
      {path: 'verify-email', component: VerifyEmailComponent},
      {path: 'reactivate-account', component: ReactivateAccountComponent},
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    RouterModule.forChild(authRoutes),
    StoreModule.forFeature('auth', fromAuth.reducer),
    EffectsModule.forFeature([AuthEffects]),
    SocialLoginModule,
    SharedModule, RecaptchaModule, RecaptchaFormsModule, VendorBuyerSharedModule
  ],
  declarations: [RegisterComponent, LoginComponent, ForgotPasswordComponent,
    ResetPasswordComponent, ValidateOtpComponent, ReactivateAccountComponent,
    AuthComponent, VerifyEmailComponent, ResetVerificationComponent],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useFactory: () => {
        return {
          autoLogin: false,
          providers: [
            {
              id: GoogleLoginProvider.PROVIDER_ID,
              provider: new GoogleLoginProvider(AuthModule.environment.google_key)
            }
          ]
        } as SocialAuthServiceConfig;
      }
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useFactory: () => {
        return {
          siteKey: AuthModule.environment.google_recaptcha_site_key
        } as RecaptchaSettings
      }
    }
  ]
})
export class AuthModule {
  static environment: any;
}
