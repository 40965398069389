<div class="container-fluid">
  <div class="dashboard">
    <h1 class="fw-bold pt-18 fs-48">{{'greeting'| translate: {username: currentUser?.firstName} }}
      <span></span></h1>

    <h4 class="fw-bold fs-32 pt-32 pb-14"
        *ngIf="adminDashboard?.unVerifiedVendorCount > 0">{{'store_verification_pending' | translate}}</h4>
    <div class="row" *ngIf="adminDashboard?.unVerifiedVendorCount > 0">
      <div class="col-md-6">
        <div class="store-pending store-verification">
          <div class="store-message">
            <img src="/assets/images/icons/store-check.svg"/>
            <span class="fs-16">{{'store_verification_pending' | translate}}</span>
          </div>
          <span class="count cursor-pointer" [state]="{ filter: 'awaitApproval' }"
                [routerLink]="['/admin', 'sellers']">{{adminDashboard?.unVerifiedVendorCount || 0}}</span>
        </div>
      </div>
      <div class="col-md-6" *ngIf="adminDashboard?.returnRequests > 0">
        <div class="store-pending store-verification">
          <div class="store-message">
            <img src="/assets/images/icons/pending_return.svg"/>
            <span class="fs-16">{{'return_escalations' | translate}}</span>
          </div>
          <span class="count cursor-pointer"
                [routerLink]="['/admin', 'orders']"
                [queryParams]="{return: true}">{{adminDashboard?.returnRequests || 0}}</span>
        </div>
      </div>
    </div>
    <div class="">
      <!--      <ng-container *ngIf="currentUser?.role === 'MANAGEMENT' || currentUser?.role === 'IT'">-->
      <ng-container>
        <div class="platform-stats pt-40">
          <div class="row">
            <div class="col-lg-10">
              <h4 class="platform-title fs-32 fw-bold">{{'platform_stats' | translate}}</h4>
            </div>
            <div class="col-lg-2">
              <div class="form-group dropdown">
                <kwot-custom-select
                  class="filter-wallet-data"
                  [searchable]="true"
                  [placeholder]="'Select Date'"
                  [selectOptions]="month"
                  (optionSelected)="filterData($event)"
                  [(selectedValue)]="monthRange">
                </kwot-custom-select>
              </div>
            </div>
          </div>
        </div>
        <div class="platform-stats components">
          <div class="row">
            <div class="col-lg-8">
              <div class="store-pending store-chart d-block">
                <div class="store-message">
                  <img src="/assets/images/icons/total-orders.svg"/>
                  <span class="fs-14">{{'total_orders' | translate}}</span>
                  <p class="pt-2 fs-32 fw-bold mb-0">{{adminDashboard?.totalOrder || 0}}</p>
                </div>
                <div class="chart">
                  <apx-chart
                    [series]="chartOptions.series"
                    [grid]="chartOptions.grid"
                    [chart]="chartOptions.chart"
                    [xaxis]="chartOptions.xaxis"
                    [stroke]="chartOptions.stroke"
                    [colors]="chartOptions.colors"
                    [yaxis]="chartOptions.yaxis"
                    [dataLabels]="chartOptions.dataLabels"
                    [tooltip]="chartOptions.tooltip"
                  >
                  </apx-chart>
                </div>
              </div>
              <div class="store-pending">
                <div class="store-message">
                  <span class="fs-14">{{'total_revenue' | translate}}</span>
                  <p class="total-count fs-24 fw-bold pb-14">{{totalPrice || 0 | currencyConvert | async}}</p>
                </div>
                <div class="store-message">
                  <span class="fs-14">{{'net_revenue' | translate}}</span>
                  <p class="total-count fs-24 fw-bold pb-14">{{netRevenue || 0 | currencyConvert | async}}</p>
                </div>
                <div class="store-message">
                  <span class="fs-14">{{'sellers_profit' | translate}}</span>
                  <p class="total-count fs-24 fw-bold pb-14">{{profit || 0 | currencyConvert | async}}</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="row">
                <div class="col-lg-6">
                  <div class="store-pending">
                    <div class="store-message">
                      <img src="/assets/images/icons/total-buyers.svg"/>
                      <span class="fs-14">{{'total_buyers' | translate}}</span>
                      <p class="total-count fs-24 fw-bold">{{adminDashboard?.totalBuyer || 0}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="store-pending">
                    <div class="store-message">
                      <img src="/assets/images/icons/new-buyers.svg"/>
                      <span class="fs-14">{{'new_buyers' | translate}}</span>
                      <p class="total-count fs-24 fw-bold">{{adminDashboard?.totalNewBuyer || 0}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="store-pending">
                    <div class="store-message">
                      <img src="/assets/images/icons/new-buyers.svg"/>
                      <span class="fs-14">{{'completed_orders' | translate}}</span>
                      <p class="total-count fs-24 fw-bold">{{adminDashboard?.endedOrder || 0}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="store-pending">
                    <div class="store-message">
                      <img src="/assets/images/icons/new-buyers.svg"/>
                      <span class="fs-14">{{'pending_orders' | translate}}</span>
                      <p class="total-count fs-24 fw-bold">{{adminDashboard?.pendingOrder || 0}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="store-pending">
                    <div class="store-message">
                      <img src="/assets/images/icons/open-tickets.svg"/>
                      <span class="fs-14">{{'return_request' | translate}}</span>
                      <p class="total-count fs-24 fw-bold">{{adminDashboard?.returnRequests || 0}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="store-pending">
                    <div class="store-message">
                      <img src="/assets/images/icons/solved-tickets.svg"/>
                      <span class="fs-14">{{'canceled_orders' | translate}}</span>
                      <p class="total-count fs-24 fw-bold">{{adminDashboard?.canceldOrder || 0}}</p>
                    </div>
                  </div>
                </div>
                <!--                <div class="col-lg-6">-->
                <!--                  <div class="store-pending">-->
                <!--                    <div class="store-message">-->
                <!--                      <img src="/assets/images/icons/open-tickets.svg"/>-->
                <!--                      <span class="fs-14">{{'open_tickets' | translate}}</span>-->
                <!--                      <p class="total-count fs-24 fw-bold">0</p>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--                <div class="col-lg-6">-->
                <!--                  <div class="store-pending">-->
                <!--                    <div class="store-message">-->
                <!--                      <img src="/assets/images/icons/solved-tickets.svg"/>-->
                <!--                      <span class="fs-14">{{'solved_tickets' | translate}}</span>-->
                <!--                      <p class="total-count fs-24 fw-bold">0</p>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->
                <div class="col-lg-6">
                  <div class="store-pending">
                    <div class="store-message">
                      <img src="/assets/images/icons/total-sallers.svg"/>
                      <span class="fs-14">{{'total_sellers' | translate}}</span>
                      <p class="total-count fs-24 fw-bold cursor-pointer"
                         [routerLink]="['/admin', 'sellers']">{{adminDashboard?.totalSeller || 0}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="store-pending">
                    <div class="store-message">
                      <img src="/assets/images/icons/new-sellers.svg"/>
                      <span class="fs-14">{{'new_sellers' | translate}}</span>
                      <p class="total-count fs-24 fw-bold">{{adminDashboard?.totalNewSeller || 0}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

