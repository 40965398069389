import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators} from "@angular/forms";
import {Subject, takeUntil} from "rxjs";
import {select, Store} from "@ngrx/store";
import {
  AuthState,
  CheckResetPasswordLink, getAuthError,
  getAuthSuccess,
  ResetAuthState,
  ResetPassword
} from "@kwot/auth";
import {ActivatedRoute, Router} from "@angular/router";
import {APP_CONFIG, LocalstorageService} from "@kwot/app-config";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'kwot-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  resetPasswordForm: UntypedFormGroup;
  unsubscriber = new Subject();
  showPassword = false;
  confirmShowPassword = false;
  RegExpValidator = {
    'lowerCase': RegExp(/^(?=.*?[a-z])/),
    'upperCase': RegExp(/^(?=.*?[A-Z])/),
    'digit': RegExp(/^(?=.*?[0-9])/),
    'specialChar': RegExp(/^(?=.*?[" !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"])/),
    'email': RegExp(/^[a-zA-z0-9._%+-]+@[a-zA-z0-9.-]+\.[a-zA-z]{2,4}$/)
  };
  requestForResetLink = false;
  token = '';
  isResetWithLink = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authStore: Store<AuthState>,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private toastr: ToastrService,
    private translateService: TranslateService,
    @Inject(APP_CONFIG) public appConfig: any,
    private localStorageService: LocalstorageService
  ) {
    this.authStore.dispatch(ResetAuthState({params: {error: '', success: ''}}));
    this.subscribeToStore();
    this.activeRoute.queryParams.subscribe(query => {
      if (query && query.token) {
        this.isResetWithLink = true;
        this.token = query.token;
        this.requestForResetLink = true;
        this.authStore.dispatch(CheckResetPasswordLink({id: query.token}));
      } else {
        this.isResetWithLink = false;
      }
    })
  }

  subscribeToStore() {
    this.authStore.pipe(select(getAuthSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          this.localStorageService.updateRegisterUser('remove');
          this.toastr.success(this.translateService.instant('reset_password_success'));
          this.router.navigate(['/', this.appConfig.type, 'login'])
        }
      })
    this.authStore.pipe(select(getAuthError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          if (this.requestForResetLink) {
            this.localStorageService.updateRegisterUser('remove');
            this.requestForResetLink = false;
            this.toastr.error(this.translateService.instant('reset_password_link_expired'), this.translateService.instant('expired'));
            this.router.navigate(['/', this.appConfig.type, 'login'])
          }
        }
      })
  }

  ngOnInit(): void {
    this.resetPasswordForm = this.formBuilder.group({
      newPassword: ['', Validators.compose([
        Validators.required, Validators.minLength(8), Validators.maxLength(30),
        this.validate('lowerCase', this.RegExpValidator.lowerCase), this.validate('upperCase', this.RegExpValidator.upperCase),
        this.validate('digit', this.RegExpValidator.digit), this.validate('specialChar', this.RegExpValidator.specialChar)
      ])],
      confirmNewPassword: ['', Validators.required],
      ...(this.isResetWithLink ? {token: [this.token, Validators.required]} : {})
    }, {
      validator: this.passwordMatchValidator
    })
  }

  validate(criteria: string, regex: RegExp): ValidatorFn {
    return (control: AbstractControl): any => {
      if (!control || !control.value || control.value.length === 0) {
        return undefined;
      }
      if (!regex.test(control.value)) {
        const failed: any = {};
        failed[criteria] = {
          actualValue: control.value,
          requiredPattern: regex
        };
        return failed;
      }
      return undefined;
    };
  }

  passwordMatchValidator(frm: UntypedFormGroup) {
    if (frm.controls['confirmNewPassword'].errors && !frm.controls['confirmNewPassword'].errors.confirmPasswordValidator) {
      return;
    }
    if (frm.controls['newPassword'].value !== frm.controls['confirmNewPassword'].value) {
      frm.controls['confirmNewPassword'].setErrors({confirmPasswordValidator: true});
    } else {
      frm.controls['confirmNewPassword'].setErrors(null);
    }
  }


  get form() {
    return this.resetPasswordForm.controls;
  }

  submitForm() {
    if (this.resetPasswordForm.invalid) {
      return;
    }

    const formValues = {...this.resetPasswordForm.value};
    const body: any = {
      password: formValues.newPassword,
      ...(this.isResetWithLink ? {
        link: formValues.token
      } : {})
    }

    this.authStore.dispatch(ResetPassword({passwordBody: body}));
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
  }

}
