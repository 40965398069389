import {Action, createReducer, on} from "@ngrx/store";
import {
  GetCurrencyData, GetCurrencyDataError, GetCurrencyDataSuccess,
  ResetSharedState,
  SaveMeasurements,
  SaveMeasurementsError,
  SaveMeasurementsSuccess,
} from "./shared.actions";
import {CurrencyData} from "@kwot/data-models";

export interface SharedState {
  success: string;
  error: string;
  measurements: any;
  currencyData: CurrencyData;
}

const initSharedState: SharedState = {
  success: '', error: '',
  measurements: null, currencyData: null
}

const sharedReducer = createReducer(initSharedState,
  on(SaveMeasurements, (state) => ({...state, error: '', success: ''})),
  on(SaveMeasurementsSuccess, (state, {measurements}) => ({...state, error: '', success: '---IGNORE---', measurements})),
  on(SaveMeasurementsError, (state, {error}) => ({...state, error, success: ''})),

  on(GetCurrencyData, (state) => ({...state, error: '', success: ''})),
  on(GetCurrencyDataSuccess, (state, {currencyData}) => ({...state, error: '', success: '---IGNORE---', currencyData})),
  on(GetCurrencyDataError, (state, {error}) => ({...state, error, success: ''})),

  on(ResetSharedState, (state, {params}) => ({
    ...state,
    ...params
  }))
)

export function reducer(state = initSharedState, action: Action) {
  return sharedReducer(state, action);
}
