import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {CustomTableComponent} from "@kwot/admin-vendor-shared";
import {TranslateService} from "@ngx-translate/core";
import {select, Store} from "@ngrx/store";
import {APP_CONFIG} from "@kwot/app-config";
import {AdminState} from "../../+state/admin.reducer";
import {ResetAdminState, GetReviews, DeleteReviews} from "../../+state/admin.actions";
import {getReviews} from "../../+state/admin.selectors";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ReviewInfoComponent} from "../review-info/review-info.component";

@Component({
  selector: 'kwot-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {

  selectedFilter: string = 'product';
  filters: any[] = [
    {
      value: 'product',
      label: 'Product Reviews',
    },
    {
      value: 'seller',
      label: 'My Reviews',
    },
  ];
  customDatatableSettings: any = {};
  reviewsList: any[] = [];
  unsubscriber = new Subject();
  editId = '';
  @ViewChild('customTableComponent', {static: true}) customTableComponent: CustomTableComponent;
  isDeleteAction = false;
  isFetching = false;
  modalRef?: BsModalRef | null;
  reviewData : any;
  constructor(
    private translateService: TranslateService,
    private vendorStore: Store<AdminState>,
    @Inject(APP_CONFIG) public appConfig: any,
    private modelService: BsModalService,
  ) {
    this.vendorStore.dispatch(ResetAdminState({params: {error: '', success: '', reviews: null}}));
    this.subscribeToStore();
  }

  subscribeToStore() {
    this.vendorStore.pipe(select(getReviews))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(reviews => {
        if (reviews) {
          this.reviewsList = [...reviews.data];
          if (this.customTableComponent.ajaxCallback) {
            this.customTableComponent.ajaxCallback({
              recordsTotal: reviews.count.total,
              recordsFiltered: reviews.count.total,
              data: []
            })

            setTimeout(() => {
              this.customTableComponent.reAdjustColumnsInTable();
            }, 500);
          }
        }
      })
  }

  ngOnInit(): void {
    this.customDatatableSettings = {
      paging: true,
      serverSide: true,
      ordering: false,
      columns: [{data: null}, {data: null}, {data: null}, {data: null}],
      addAjax: true,
      searchPlaceholder: this.translateService.instant('search_by', {field: this.translateService.instant('products_no_bracket')})
    }
  }

  getAllReviews = (page = 0, perPage = 10, search = '') => {
    this.vendorStore.dispatch(GetReviews({
      params: {
        reviewType: this.selectedFilter,
        page, limit: perPage,
        search
      }
    }))
  }

  filterProduct(value: string) {
    this.selectedFilter = value
    this.getAllReviews(undefined, undefined, undefined);
  }

  reviewInfo(review: any) {
    const modelRef: BsModalRef = this.modelService.show(ReviewInfoComponent, {
      keyboard: true,
      animated: true,
      ignoreBackdropClick: false,
      class: 'modal-md modal-dialog-centered custom-model',
      backdrop: true,
      initialState: {
          reviewInfo: review
      }
    });

  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete()
  }

  deleteReviewModel(template: any, review: any) {
    this.reviewData = review;
    this.modalRef = this.modelService.show(template, {
      keyboard: true,
      animated: true,
      ignoreBackdropClick: false,
      class: 'modal-md modal-dialog-centered custom-model'
    })
  }



  deleteReview() {
    this.vendorStore.dispatch(DeleteReviews({params : {
        id: this.reviewData._id,
        reviewType: this.selectedFilter,
      }}));
    this.modalRef?.hide()
  }

}
