<div class="auth-component">
  <span id="close"><img class="" [routerLink]="['/', appConfig.type, 'login']" src="assets/images/icons/Cross.svg" alt=""></span>
  <img class="bg-right--img d-none d-md-block" src="assets/images/bg/auth-Bg.png" alt="">
  <img class="bg-right--img d-md-none" src="assets/images/bg/auth-mobile-Bg.png" alt="">
  <div class="container">
    <div class="row">
      <div class="col-xl-4 col-md-5 pt-56 pt-md-36">
        <div class="action--form">
          <form class="forgot-password-form" (ngSubmit)="!forgotPasswordForm.invalid && submitForm()" novalidate
                [formGroup]="forgotPasswordForm">
            <h1 class="register-title text-center">{{'forgot_your_password' | translate}}</h1>
            <kwot-email-phone [formGroup]="forgotPasswordForm"
                              type="forgot_password"
                              (onTypeChange)="changeLoginType($event)"></kwot-email-phone>
            <button type="submit" class="my-4 btn-action w-100 p-14"
                    [ngClass]="{'btn-disabled': forgotPasswordForm.invalid, 'btn-action': forgotPasswordForm.valid}">
              {{'reset_password' | translate }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
