import {Directive, ElementRef, HostListener} from '@angular/core';
import {ControlValueAccessor, NgControl} from "@angular/forms";

@Directive({
  selector: '[inputRef]'
})
export class InputRefDirective {

  constructor(private ngControl: NgControl) {
    this.trimValueAccessor(ngControl.valueAccessor)
  }

  trimValueAccessor(valueAccessor: ControlValueAccessor) {
    const original = valueAccessor.registerOnChange;

    valueAccessor.registerOnChange = (fn: (_: unknown) => void) => {
      return original.call(valueAccessor, (value: unknown) => {
        return fn(typeof value === 'string' ? value.trim() : value);
      });
    };
  }
}
